import { Fragment } from 'react'
import chunk from 'lodash/chunk'
import styled, { css } from 'styled-components'

import { Disclaimer } from 'bl-common/src/booking/Disclaimer/Disclaimer'
import { colors } from 'bl-common/src/constants/colors'
import { gridOffsetFrom, gridOffsetTo } from 'bl-common/src/constants/sizes'
import { zIndex } from 'bl-common/src/constants/zIndex'
import { ArrowLink } from 'bl-common/src/elements/ArrowLink'
import { ContentfulImage } from 'bl-common/src/elements/ContentfulImage'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { Appear } from 'bl-common/src/units/Appear'
import { between } from 'bl-common/src/utils/between'
import { media } from 'bl-common/src/utils/media'
import { mixins } from 'bl-common/src/utils/mixins'
import { formatHtmlText } from 'bl-utils/src/formatting/formatHtmlText'

type SectionDecoratorProps = {
  gradient?: any
}

const Container = styled.div`
  ${mixins.siteGutter()}
  position: relative;
  width: 100%;
  padding-right: 0;
`
const ContainerInner = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  z-index: ${zIndex.above};

  ${media.md(css`
    flex-direction: row;
  `)}
`

const Left = styled.div`
  flex: 1 1 50%;
  padding-right: ${gridOffsetFrom}px;
  padding-bottom: ${({ theme }) => theme.spacing[3.5]};
  z-index: 1;

  ${media.md(css`
    padding: ${({ theme }) => theme.spacing[6]} 0;
    padding-right: ${gridOffsetTo}px;
  `)}
`

const Right = styled.div`
  flex: 1 1 50%;
  z-index: 0;
`

const ImageWrapper = styled.div`
  position: relative;
  flex: none;
  height: 447px;
  width: 100%;

  ${media.md(css`
    height: 100%;
  `)}
`

const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--color-fillPrimary);
  padding: ${({ theme }) => theme.spacing[1.5]};
  margin: ${({ theme }) => theme.spacing[1.5]} 0;
  padding-left: ${gridOffsetFrom}px;
  margin-left: -${gridOffsetFrom}px;

  ${media.md(css`
    padding: ${({ theme }) => `${theme.spacing[2.5]} ${theme.spacing[4]}`};
    margin: ${({ theme }) => theme.spacing[2.5]} 0;
    margin-left: ${({ theme }) => theme.spacing[-4]};
    margin-right: ${({ theme }) => theme.spacing[-9]};
    flex-direction: row;
    z-index: ${zIndex.above2};
  `)}

  b {
    font-weight: 600;
  }
`

const List = styled.ul`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  margin: 0;

  ${media.md(css`
    line-height: 2;
  `)};
`

const ListItem = styled.li`
  &:nth-child(2n) {
    margin-left: auto;
    text-align: right;
  }
`

const LinkContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: ${({ theme }) => theme.spacing[0.5]};

  ${media.md(css`
    grid-template-columns: 1fr 1fr;
    row-gap: ${({ theme }) => theme.spacing[1]};
  `)};
`
const SectionDecorator = styled.div<SectionDecoratorProps>`
  position: absolute;
  top: -32px;
  bottom: 48px;
  left: ${gridOffsetFrom}px;
  right: 0;
  transform: translateX(35px);
  opacity: 0.5;

  ${media.md(`
    left: ${between(gridOffsetFrom, gridOffsetTo)};
    transform: translateX(75px);
  `)}

  .t-dark & {
    opacity: 0.1;
  }

  ${({
    gradient = {
      fields: {},
    },
  }) => {
    const { color1, color2 } = gradient.fields
    return (
      color1 &&
      color2 &&
      css`
        background: linear-gradient(${color1}, ${color2});
      `
    )
  }};
`

const renderHours = hours =>
  hours.map(hour => {
    const [date, time] = hour
    return (
      <Fragment key={date + time}>
        <ListItem>
          <Type inline size={{ xs: 14, md: 18 }}>
            {date}
          </Type>
        </ListItem>
        <ListItem>
          <Type inline size={{ xs: 14, md: 18 }} weight="bold">
            {time}
          </Type>
        </ListItem>
      </Fragment>
    )
  })

export const VisitorInfoPanel = panelInfo => {
  if (!panelInfo) {
    return null
  }

  const {
    image,
    title,
    description,
    links,
    gradient,
    openingHours = [],
    texts = [],
    warning,
  } = panelInfo

  const openingTimes = openingHours.map(item => {
    const title = item.fields.title
    const hours = chunk(item.fields.openingHours, 2)
    return { hours, title }
  })

  return (
    <Container>
      <ContainerInner>
        <Appear observer>
          <Left>
            <Appear>
              {title && (
                <Type preset="headlineLarge" as="h2" bottom={{ xs: 1 }}>
                  {title}
                </Type>
              )}
            </Appear>

            {description && (
              <Appear>
                <Type
                  maxWidth={520}
                  preset="subtitle"
                  multiline
                  dangerouslySetInnerHTML={{
                    __html: formatHtmlText(description),
                  }}
                />
              </Appear>
            )}
            {warning && (
              <Appear>
                <Disclaimer
                  color={colors.errorRed}
                  maxWidth={520}
                  preset="text"
                  multiline
                  weight="bold"
                >
                  {warning}
                </Disclaimer>
              </Appear>
            )}
            <Appear styleChild>
              <Card>
                {openingTimes.map((item, index) => (
                  <div key={index + item.title}>
                    <Type
                      weight="bold"
                      preset="text"
                      top={{ xs: 1, md: 0.5 }}
                      bottom={{ xs: 1, md: 0.5 }}
                    >
                      {item.title}
                    </Type>
                    <List>{renderHours(item.hours)}</List>
                  </div>
                ))}
                {texts.map((text, index) => (
                  <div key={index + text.fields.key}>
                    <Type
                      top={{ xs: 1, md: 0.5 }}
                      bottom={{ xs: 1, md: 0.5 }}
                      preset="text"
                      multiline
                      lineHeight={2}
                      dangerouslySetInnerHTML={{
                        __html: formatHtmlText(text.fields.content),
                      }}
                    />
                  </div>
                ))}
              </Card>
            </Appear>
            <Appear>
              {links && (
                <LinkContainer>
                  {links.map(link => (
                    <ArrowLink
                      key={link.fields.url}
                      to={link.fields.url}
                      weight="bold"
                      paddingSize="small"
                    >
                      {link.fields.label}
                    </ArrowLink>
                  ))}
                </LinkContainer>
              )}
            </Appear>
          </Left>
        </Appear>
        <Right>
          <ImageWrapper>
            <ContentfulImage image={image} />
          </ImageWrapper>
        </Right>
      </ContainerInner>
      {gradient ? <SectionDecorator gradient={gradient} /> : null}
    </Container>
  )
}
