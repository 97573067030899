import { Arrow } from 'bl-common/src/elements/Arrow/Arrow'
import { Type } from 'bl-common/src/elements/Typography/Typography'

import * as Styles from './styles'

export const ImageLink = ({ icon, link, name, text, backgroundColor }) => (
  <Styles.ImageLinkContainer to={link} image={icon} bgc={backgroundColor}>
    {name && (
      <Styles.ImageLinkTitle>
        <Type size={{ xs: 18, md: 18 }} weight="light">
          {name}
        </Type>
      </Styles.ImageLinkTitle>
    )}
    {text && (
      <Styles.ImageLinkPrompt>
        <Arrow scale={0.5} />
        <Type size={{ xs: 16, md: 16 }} weight="light">
          {text}
        </Type>
      </Styles.ImageLinkPrompt>
    )}
  </Styles.ImageLinkContainer>
)

export const ImageLinksBox = ({ imageLinks }) => (
  <Styles.ImageLinksContainer>
    {imageLinks &&
      imageLinks.map(shop => <ImageLink key={shop.id} {...shop} />)}
  </Styles.ImageLinksContainer>
)
