import { ISectionProductPanelFields } from 'bl-common/src/generated/contentful'
import { Section } from 'bl-common/src/units/Section/Section'

import { ProductPanel } from '../../ProductPanel'

type ProductPanelSectionProps = {
  section: {
    fields: ISectionProductPanelFields
  }
}

const ProductPanelSection = ({
  section: {
    fields: { title, description, card, gradient, ctaLink, name, config },
  },
}: ProductPanelSectionProps) => {
  return (
    <Section
      noHorizontalPadding
      bottom={{ xs: 4, md: 3 }}
      top={{ xs: 4, md: 3 }}
      config={config}
      hideOverflowX
    >
      <ProductPanel
        title={title}
        description={description}
        cards={card}
        gradient={gradient}
        ctaLink={ctaLink}
        name={name}
      />
    </Section>
  )
}

export default ProductPanelSection
