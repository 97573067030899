import { Type } from 'bl-common/src/elements/Typography/Typography'
import { ISectionCategoryTagsFields } from 'bl-common/src/generated/contentful'
import { Appear } from 'bl-common/src/units/Appear'
import { Section } from 'bl-common/src/units/Section/Section'
import { Tags } from 'bl-common/src/units/Tags/Tags'

type CategoryTagsSectionProps = {
  section: {
    fields: ISectionCategoryTagsFields
  }
}

const formatTag = tag =>
  tag && {
    id: tag.sys.id,
    type: tag.sys.contentType.sys.id,
    name: tag.fields.name,
    title: tag.fields.title,
    description: tag.fields.description,
    gradient: tag.fields.gradientBackground,
    slug: tag.fields.slug,
    supportArticleLink: tag.fields.supportArticleLink,
  }

export const CategoryTagsSection = ({
  section: {
    fields: { heading, subheading, categoryTags, categorySlug, config },
  },
}: CategoryTagsSectionProps) => (
  <Section
    config={config}
    top={{ xs: 2, md: 6 }}
    bottom={{ xs: 4, md: 4 }}
    hideOverflowX
  >
    <Appear observer>
      <Appear>
        <Type as="h3" preset="headline" bottom={{ xs: 0.5, md: 1 }}>
          {heading}
        </Type>
      </Appear>
      <Appear>
        <Type preset="subtitle" bottom={{ xs: 2, md: 2 }}>
          {subheading}
        </Type>
      </Appear>
      <Appear>
        <Tags
          tags={categoryTags.map(tag => formatTag(tag))}
          categorySlug={categorySlug}
        />
      </Appear>
    </Appear>
  </Section>
)

export default CategoryTagsSection
