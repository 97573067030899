import { Appear } from 'bl-common/src/units/Appear'
import { Section } from 'bl-common/src/units/Section/Section'

import { formatCollectionCard } from '../../../utils/formatters'
import { CardCollection } from '../../CardCollection'

export const CardCollectionSection = ({
  section: {
    fields: { cardCollection, config },
  },
}) => (
  <Section config={config} bottom={{ xs: 4, md: 4 }} hideOverflowX>
    <Appear>
      <CardCollection
        title={cardCollection.fields.title}
        description={cardCollection.fields.description}
        link={cardCollection.fields.link}
        gradient={cardCollection.fields.gradient}
        shouldContentScroll={cardCollection.fields.shouldContentScroll}
        cards={cardCollection.fields.cards.map(card =>
          formatCollectionCard(card)
        )}
      />
    </Appear>
  </Section>
)

export default CardCollectionSection
