import { Type } from 'bl-common/src/elements/Typography/Typography'
import { Appear } from 'bl-common/src/units/Appear'
import { Section } from 'bl-common/src/units/Section/Section'

import { formatParallaxPanel } from '../../../utils/formatters'
import { getSectionProps } from '../../../utils/getSectionProps'
import { ParallaxPanels } from '../../ParallaxPanels'

export const ParallaxPanelsSection = props => {
  const { heading, multilineSubheading, experiences, invert, config } =
    getSectionProps(props)
  const panels = experiences.map(panel => formatParallaxPanel(panel))
  return (
    <Section top={{ xs: 4, md: 8 }} bottom={{ xs: 4, md: 8 }} config={config}>
      <Appear observer>
        {heading && (
          <Appear>
            <Type as="h2" preset="headlineLarge" bottom={{ xs: 0.5, md: 1 }}>
              {heading}
            </Type>
          </Appear>
        )}
        {multilineSubheading && (
          <Appear>
            <Type
              preset="subtitle"
              bottom={{ xs: 2, md: 5 }}
              maxWidth={890}
              multiline
            >
              {multilineSubheading}
            </Type>
          </Appear>
        )}
      </Appear>
      <ParallaxPanels
        key={props.section.sys.id}
        experiences={panels}
        inverted={invert}
      />
    </Section>
  )
}

export default ParallaxPanelsSection
