import { useContext } from 'react'
import styled from 'styled-components'

import { ComparisonCard } from 'bl-common/src/cards/ComparisonCard/ComparisonCard'
import { breakpoints } from 'bl-common/src/constants/breakpoints'
import { CurrencyContext } from 'bl-common/src/context/Currency/CurrencyProvider'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { useInView } from 'bl-common/src/hooks/useInView'
import { Appear } from 'bl-common/src/units/Appear'
import { Section } from 'bl-common/src/units/Section/Section'
import {
  triggerProductClick,
  triggerProductImpressions,
} from 'bl-utils/src/analytics'

import { formatComparisonCard } from '../../../utils/formatters'

const CardsWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing[2],

  [`@media (min-width: ${breakpoints.md}px)`]: {
    flexDirection: 'row',
  },

  ['> div:nth-child(1)']: {
    alignSelf: 'flex-start',
  },

  ['> div:nth-child(3)']: {
    alignSelf: 'flex-end',
  },
}))

export const ComparisonCardsSection = ({
  section: {
    fields: { heading, multilineSubheading, comparisonCards, config },
  },
}) => {
  const { exchangeRates } = useContext(CurrencyContext)

  const reportImpressionIfRelevant = () => {
    const productReferences = comparisonCards
      .filter(card => card.fields.productReference)
      .map(card => card.fields.productReference)

    if (exchangeRates) {
      triggerProductImpressions(productReferences, exchangeRates?.EUR)
    }
  }

  const { ref } = useInView({
    unobserveOnEnter: true,
    onChange: inView => {
      if (inView) {
        reportImpressionIfRelevant()
      }
    },
  })

  const onBookClick = selectedCard => {
    if (!selectedCard) {
      return
    }

    const { productReference } = selectedCard.fields

    if (productReference && exchangeRates) {
      triggerProductClick(productReference, exchangeRates?.EUR)
    }
  }

  return (
    <Section
      top={{ xs: 6, md: 6 }}
      bottom={{ xs: 3, md: 5 }}
      config={config}
      hideOverflowX
    >
      <Appear observer>
        <Appear>
          <Type as="h2" preset="headlineLarge" bottom={{ xs: 1, md: 2 }}>
            {heading}
          </Type>
          <Type preset="subtitle" multiline bottom={{ xs: 2, md: 3 }}>
            {multilineSubheading}
          </Type>
        </Appear>

        <CardsWrapper ref={ref}>
          {comparisonCards.map(card => (
            <ComparisonCard
              {...formatComparisonCard(card)}
              key={card.id ?? card.sys.id}
              onBookClick={() => onBookClick(card)}
            />
          ))}
        </CardsWrapper>
      </Appear>
    </Section>
  )
}

export default ComparisonCardsSection
