import { useEffect, useRef } from 'react'
import styled from 'styled-components'

import { breakpoints } from '../../constants/breakpoints'
import { zIndex } from '../../constants/zIndex'
import { SmokeImage } from '../../types/custom'
import preStartData from './preStartData'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import steamImage from './steam.png'

const debug = false

const Canvas = styled.canvas({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  height: 200,
  zIndex: zIndex.above,
  transform: 'rotate(0.5turn)',
  width: '100vw',

  [`@media (min-width: ${breakpoints.md}px)`]: {
    height: 400,
  },
})

function randBetween(n1, n2) {
  return Math.random() * (n2 - n1) + n1
}

const Steam = ({ preStart, small }) => {
  const ref = useRef()
  const data = useRef([])
  if (debug) {
    setTimeout(() => console.log(data.current), small ? 5000 : 15000)
  }

  useEffect(() => {
    if (!ref || !ref.current) return
    let loopId

    // Setup
    const c = ref.current
    const ctx = (c as any).getContext('2d')
    const smokeImage: SmokeImage = new Image()
    smokeImage.src = steamImage?.src

    const particles = preStart
      ? [...preStartData[small ? 'small' : 'large']]
      : []
    const count = 200
    const offset = 50
    const growth = 10

    // Create particles
    if (!preStart) {
      for (let p = 0; p < count; p++) {
        setParticle(p * offset, p)
      }
    }

    draw()

    function setParticle(delay, index) {
      const p: any = {}
      p.top = (c as any).height
      p.left = randBetween(-200, 800)

      p.start = new Date().getTime() + delay
      p.life = small ? 8000 : 12000

      p.speedUp = 20
      p.speedRight = randBetween(0, 20)

      p.startOpacity = 1
      p.size = 200

      particles[index] = p
      if (debug) {
        data.current[index] = {
          ...p,
          start: undefined,
        }
      }
    }

    function draw() {
      ctx.clearRect(0, 0, (c as any).width, (c as any).height)

      particles.forEach((p, i) => {
        // Timing
        const timeInMS = new Date().getTime()
        if ((p as any).start === undefined) (p as any).start = timeInMS
        const timeAlive = timeInMS - (p as any).start
        const frac = timeAlive / p.life

        if (timeAlive > 0) {
          if (timeAlive <= p.life) {
            // attributes that change over time
            const newTop = p.top - p.speedUp * (timeAlive / 1000)
            const newLeft = p.left - p.speedRight * (timeAlive / 1000)
            const newOpacity = Math.max(p.startOpacity * (1 - frac), 0)
            const newSize = p.size + growth * (timeAlive / 1000)

            // update particle
            p.newTop = newTop
            p.newLeft = newLeft

            // Draw!
            ctx.fillStyle = `rgba(255, 255, 255, ${newOpacity})`
            ctx.globalAlpha = newOpacity
            ctx.drawImage(smokeImage, newLeft, newTop, newSize, newSize)
            if (debug) {
              data.current[i] = {
                ...p,
                top: newTop,
                left: newLeft,
                startOpacity: newOpacity,
                size: newSize,
                start: undefined,
                life: p.life - timeAlive,
              }
            }
          } else {
            setParticle(i * offset, i)
          }
        }
      })

      // render loop
      loopId = requestAnimationFrame(function () {
        draw()
      })

      return () => {
        cancelAnimationFrame(loopId)
      }
    }
  }, [ref.current, small])

  return <Canvas ref={ref} height="200" width="800"></Canvas>
}

export default Steam
