import { Appear } from 'bl-common/src/units/Appear'
import { DownloadableImagePanel } from 'bl-common/src/units/DownloadableImagePanel'
import { OffsetSection } from 'bl-common/src/units/Section/OffsetSection'

import { formatGradient } from '../../../utils/formatters'
import { getSectionProps } from '../../../utils/getSectionProps'

const DownloadableImageSection = props => {
  const { config, offsetDirection, backgroundGradient, ...rest } =
    getSectionProps(props)
  const formattedGradient = formatGradient(backgroundGradient)

  return (
    <Appear>
      <OffsetSection
        offsetDirection={offsetDirection}
        background={formattedGradient}
        config={config}
      >
        <DownloadableImagePanel {...rest} offsetDirection={offsetDirection} />
      </OffsetSection>
    </Appear>
  )
}

export default DownloadableImageSection
