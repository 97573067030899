import { colors } from 'bl-common/src/constants/colors'
import { Link } from 'bl-common/src/elements/Link'
import { Type } from 'bl-common/src/elements/Typography/Typography'

import * as styles from './styles'

export const FeatureCard = ({
  heading,
  subheading,
  subheadingColor = 'deepBlue',
  text,
  link,
}) => {
  return (
    <styles.FeatureCardWrapper>
      {subheading ? (
        <Type
          preset="labelTiny"
          weight="bold"
          color={colors[subheadingColor]}
          case="uppercase"
          bottom={{ xs: 0.5, md: 0 }}
        >
          {subheading}
        </Type>
      ) : null}
      <Type preset="subtitle" weight="bold" bottom={{ xs: 0.5 }}>
        {heading}
      </Type>
      {text && (
        <Type preset="text" top={{ xs: 1, md: 0.5 }}>
          {text}
        </Type>
      )}
      {link ? (
        <Link to={link.fields.url}>
          <Type
            preset="textLarge"
            weight="medium"
            color={colors.deepBlue}
            top={{ xs: 1 }}
          >
            {link.fields.label}
          </Type>
        </Link>
      ) : null}
    </styles.FeatureCardWrapper>
  )
}

export default FeatureCard
