import styled, { css } from 'styled-components'

import { durations } from '../../constants/durations'
import { SmartImage } from '../../units/SmartImage/SmartImage'

type ImageProps = {
  image?: string
  size?: string
}

const BackgroundImage = styled.div<ImageProps>`
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  transform: scale(1);
  transition: transform ${durations.medium}ms;

  ${props =>
    props.image &&
    css`
      background-image: url(${props.image});
    `};

  ${({ size }) => {
    if (size && size === 'Short') {
      return css`
        padding-top: 84%;
      `
    }
    if (size && size === 'Tall') {
      return css`
        padding-top: 133.333%;
      `
    }
    if (size && size === 'Full Width') {
      return css`
        padding-top: 59.51%;
      `
    }
  }};
`

const GalleryItem = styled.div`
  &:hover {
    ${BackgroundImage} {
      transform: scale(1.033);
    }
  }
`

const getProps = props => {
  if (props.item) {
    return {
      image: props.item.image,
      size: props.item.size,
    }
  }
  return props
}

const GalleryImage = props => {
  const { image, size } = getProps(props)

  return (
    <GalleryItem>
      <SmartImage image={image}>
        {imageProps => <BackgroundImage image={imageProps} size={size} />}
      </SmartImage>
    </GalleryItem>
  )
}

export default GalleryImage
