import { Type } from 'bl-common/src/elements/Typography/Typography'
import { Appear } from 'bl-common/src/units/Appear'
import { Section } from 'bl-common/src/units/Section/Section'

import { getSectionProps } from '../../../utils/getSectionProps'
import { InfoColumnsGrid, InfoColumnsRow } from '../../InfoColumns'

const InfoColumnsSection = props => {
  const {
    heading,
    multilineSubheading,
    gradient,
    textColor,
    columns,
    layout,
    config,
  } = getSectionProps(props)
  return (
    <Section bottom={{ xs: 4, md: 6 }} top={{ xs: 4, md: 6 }} config={config}>
      <Appear observer>
        <Appear>
          {heading && (
            <Type as="h2" preset="headlineLarge" bottom={{ xs: 1, md: 2 }}>
              {heading}
            </Type>
          )}
          {multilineSubheading && (
            <Type as="h3" preset="subtitle" multiline bottom={{ xs: 2, md: 3 }}>
              {multilineSubheading}
            </Type>
          )}
        </Appear>
      </Appear>
      {columns && (
        <>
          {layout === 'grid' ? (
            <InfoColumnsGrid items={columns} />
          ) : (
            <InfoColumnsRow
              items={columns}
              gradient={gradient}
              textColor={textColor}
            />
          )}
        </>
      )}
    </Section>
  )
}

export default InfoColumnsSection
