import styled, { css } from 'styled-components'

import { colors } from 'bl-common/src/constants/colors'
import { Amount } from 'bl-common/src/elements/Amount'
import { Button } from 'bl-common/src/elements/Button/Button'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { media } from 'bl-common/src/utils/media'

type CardProps = {
  image?: string
}

export const Container = styled.div`
  position: relative;
  color: ${colors.white};
  overflow: hidden;

  ${media.md(css`
    overflow: initial;
    &::before {
      content: '';
      display: block;
      padding-top: 52.7%;
    }
  `)};
`

export const Card = styled.div<CardProps>`
  background-image: ${props => props.image && `url(${props.image})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  top: 0;
  left: 0;
  padding: 56px 36px;
  overflow: hidden;
  ${media.md(css`
    padding: ${({ theme }) => theme.spacing[7.5]}
      ${({ theme }) => theme.spacing[5]};
    width: 68.74%;
    position: absolute;
  `)};

  &::before {
    content: '';
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    background-image: linear-gradient(
      46deg,
      rgba(0, 0, 0, 0.75) 0%,
      rgba(0, 0, 0, 0.46) 99%
    );
  }
`

export const Content = styled.div`
  position: relative;
`

export const InclusionCard = styled.div`
  background-image: linear-gradient(137deg, #303437 0%, #4c393e 100%);
  height: 100%;
  line-height: 2;
  padding: 16px;
  display: flex;
  position: relative;
  flex-direction: column;

  ${media.md(css`
    padding: ${({ theme }) => theme.spacing[5.5]}
      ${({ theme }) => theme.spacing[1]};
    padding-bottom: ${({ theme }) => theme.spacing[1]};
    width: 37.48%;
    position: absolute;
    top: ${({ theme }) => theme.spacing[4]};
    right: 0;
  `)};
`

export const InclusionContent = styled.div`
  padding: 36px 0 16px 20px;

  ${media.md(css`
    padding-top: 0;
    padding-left: ${({ theme }) => theme.spacing[1.5]};
  `)};
`

export const List = styled.ul`
  padding-bottom: ${({ theme }) => theme.spacing[2]};
`

export const Item = styled.li``

export const StyledButton = styled(Button)`
  margin-top: auto;
  display: block;
`

export const InfoPanel = ({
  title,
  infoList,
  description,
  image,
  url,
  buttonText,
  listTitle,
  price,
  priceRef,
  priceFormat,
}) => (
  <Container>
    <Card image={image}>
      <Content>
        {
          <Type preset="headline" bottom={{ xs: 0.5, md: 1 }}>
            {title}
          </Type>
        }
        <Type multiline preset="subtitle" bottom={{ xs: 3, md: 3 }}>
          {description}
        </Type>
        <Type as="small" preset="text" bottom={{ xs: 1, md: 2 }}>
          <Amount value={priceRef} format={priceFormat} fallback={price} />
        </Type>
      </Content>
    </Card>
    <InclusionCard>
      <InclusionContent>
        <Type preset="label" bottom={{ xs: 0.5, md: 1 }} case="uppercase">
          {listTitle}
        </Type>
        {infoList && (
          <List>
            {infoList.map(inclusion => (
              <Item key={inclusion}>
                <Type preset="text">{inclusion}</Type>
              </Item>
            ))}
          </List>
        )}
      </InclusionContent>
      {url && (
        <StyledButton paddingSize="large" to={url} preset="dark">
          {buttonText}
        </StyledButton>
      )}
    </InclusionCard>
  </Container>
)
