import { useState } from 'react'
import { observe, useObserver, viewportWidth } from 'react-ui-observer'
import chunk from 'lodash/chunk'
import styled, { css } from 'styled-components'

import { breakpoints } from 'bl-common/src/constants/breakpoints'
import { zIndex } from 'bl-common/src/constants/zIndex'
import { Button } from 'bl-common/src/elements/Button/Button'
import { ContentfulImage } from 'bl-common/src/elements/ContentfulImage'
import { Photos } from 'bl-common/src/elements/Icons/Photos'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { ISectionAlbumCollageFields } from 'bl-common/src/generated/contentful'
import { Appear } from 'bl-common/src/units/Appear'
import { CarouselModal } from 'bl-common/src/units/CarouselModal'
import { Section } from 'bl-common/src/units/Section/Section'
import { media } from 'bl-common/src/utils/media'

type ClickableImageProps = { onClick: () => void } & React.ComponentProps<
  typeof ContentfulImage
>

type AlbumCollageSectionProps = {
  section: {
    fields: ISectionAlbumCollageFields
  }
}

export const Container = styled.div`
  position: relative;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;

  ${media.md(css`
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 40px;
  `)}
`

export const ImageGrid = styled.div`
  display: grid;
  gap: 16px;

  ${media.md(css`
    gap: 32px;
  `)}
`

export const Heading = styled.div``

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.md(css`
    align-items: end;
  `)}
`

export const ButtonContainer = styled.div`
  display: flex;
`

export const OpenAlbumModalButton = styled(Button).attrs({ preset: 'white' })`
  color: rgba(58, 126, 141, 1);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);

  ${media.md(css`
    position: absolute;
    bottom: 24px;
    left: 24px;
    z-index: ${zIndex.above};
  `)}
`

export const LinkText = styled.span`
  display: flex;
  align-items: center;
`

export const AlbumContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${media.md(css`
    height: 80vh;
    display: grid;
    gap: 32px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  `)}
`

export const ImageWrapper = styled.button`
  position: relative;
  cursor: pointer;
`

export const AlbumHorizontalSplit = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  ${media.md(css`
    gap: 32px;
  `)}
`

export const AlbumVerticalSplit = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  ${media.md(css`
    gap: 32px;
  `)}
`

const ClickableImage = ({ onClick, ...props }: ClickableImageProps) =>
  props.image ? (
    <ImageWrapper onClick={onClick} aria-label="Open image carousel">
      <ContentfulImage {...props} />
    </ImageWrapper>
  ) : null

const AlbumCollageSection = ({
  section: {
    fields: {
      heading,
      multilineSubheading,
      carousel,
      ctaLink,
      showAllImages = false,
      config,
    },
  },
}: AlbumCollageSectionProps) => {
  const [showModal, setShowModal] = useState(false)
  const [currentSlide, setCurrentSlide] = useState(0)

  // TODO: Switch to useBreakpoints
  const isMobile = useObserver(
    observe(viewportWidth(), width => width < breakpoints.md)
  )

  const onClick = (index: number) => {
    setCurrentSlide(index)
    setShowModal(true)
  }

  const imgChunks = showAllImages ? chunk(carousel, 4) : [carousel.slice(0, 4)]

  return (
    <>
      <Section top={{ xs: 2, md: 6 }} bottom={{ xs: 4, md: 6 }} config={config}>
        <Container>
          <Appear observer>
            <>
              <Content>
                <Heading>
                  <Type
                    as="h3"
                    preset="headlineLarge"
                    weight="bold"
                    bottom={{ xs: 1, md: 1.5 }}
                  >
                    {heading}
                  </Type>
                  {multilineSubheading ? (
                    <Type size={{ xs: 16, md: 24 }} bottom={{ xs: 1.5, md: 1 }}>
                      {multilineSubheading}
                    </Type>
                  ) : null}
                </Heading>

                <ButtonGroup>
                  {ctaLink && (
                    <ButtonContainer>
                      <Button
                        paddingSize={isMobile ? 'medium' : 'large'}
                        to={ctaLink.fields.url}
                      >
                        {ctaLink.fields.label}
                      </Button>
                    </ButtonContainer>
                  )}
                  <OpenAlbumModalButton onClick={() => onClick(0)}>
                    <LinkText>
                      <Photos />
                      <Type
                        as="span"
                        size={{ xs: 14 }}
                        weight="bold"
                        left={{ xs: 0.5, md: 1 }}
                        right={{ md: 1 }}
                      >{`All Photos (${carousel.length})`}</Type>
                    </LinkText>
                  </OpenAlbumModalButton>
                </ButtonGroup>
              </Content>
              <ImageGrid>
                {imgChunks.map((img, index) => (
                  <AlbumContainer key={index}>
                    {isMobile ? (
                      <>
                        <ClickableImage
                          image={img[0]}
                          fill={false}
                          style={{ height: 'auto' }}
                          onClick={() => {
                            onClick(carousel.indexOf(img[0]))
                          }}
                        />
                        <ClickableImage
                          image={img[1]}
                          fill={false}
                          style={{ height: 'auto' }}
                          onClick={() => {
                            onClick(carousel.indexOf(img[1]))
                          }}
                        />
                        <AlbumVerticalSplit>
                          <ClickableImage
                            image={img[2]}
                            fill={false}
                            style={{ height: 'auto' }}
                            onClick={() => {
                              onClick(carousel.indexOf(img[2]))
                            }}
                          />
                          <ClickableImage
                            image={img[3]}
                            fill={false}
                            style={{ height: 'auto' }}
                            onClick={() => {
                              onClick(carousel.indexOf(img[3]))
                            }}
                          />
                        </AlbumVerticalSplit>
                      </>
                    ) : (
                      <>
                        <ClickableImage
                          image={img[0]}
                          onClick={() => {
                            onClick(carousel.indexOf(img[0]))
                          }}
                        />
                        <AlbumHorizontalSplit>
                          <ClickableImage
                            image={img[1]}
                            onClick={() => {
                              onClick(carousel.indexOf(img[1]))
                            }}
                          />
                          <AlbumVerticalSplit>
                            <ClickableImage
                              image={img[2]}
                              onClick={() => {
                                onClick(carousel.indexOf(img[2]))
                              }}
                            />
                            <ClickableImage
                              image={img[3]}
                              onClick={() => {
                                onClick(carousel.indexOf(img[3]))
                              }}
                            />
                          </AlbumVerticalSplit>
                        </AlbumHorizontalSplit>
                      </>
                    )}
                  </AlbumContainer>
                ))}
              </ImageGrid>
            </>
          </Appear>
        </Container>
      </Section>
      <CarouselModal
        show={showModal}
        onHide={() => setShowModal(false)}
        currentSlide={currentSlide}
        carousel={carousel}
        sliderId="album-collage"
      />
    </>
  )
}

export default AlbumCollageSection
