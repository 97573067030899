import get from 'lodash/get'

import { Type } from 'bl-common/src/elements/Typography/Typography'
import { Appear } from 'bl-common/src/units/Appear'
import { Section } from 'bl-common/src/units/Section/Section'

import { formatGradient, formatPanelPair } from '../../../utils/formatters'
import { PanelPair } from '../../PanelPair'

export const PanelPairSection = ({
  section: {
    fields: { heading, multilineSubheading, panelPair, config },
  },
}) => {
  const panels = get(panelPair, 'fields.panels')
  return (
    <Section bottom={{ xs: 2, md: 4 }} top={{ xs: 4, md: 4 }} config={config}>
      <Appear observer>
        {heading && (
          <Appear>
            <Type as="h2" preset="headlineLarge" bottom={{ xs: 0.5, md: 1 }}>
              {heading}
            </Type>
          </Appear>
        )}
        {multilineSubheading && (
          <Appear>
            <Type
              preset="subtitle"
              bottom={{ xs: 3, md: 6 }}
              maxWidth={858}
              multiline
            >
              {multilineSubheading}
            </Type>
          </Appear>
        )}
        {panels && (
          <PanelPair
            panels={panels.map(panel => formatPanelPair(panel))}
            frameGradient={formatGradient(
              get(panelPair, 'fields.frameGradient')
            )}
            frameOffsetDirection={get(panelPair, 'fields.frameOffsetDirection')}
          />
        )}
      </Appear>
    </Section>
  )
}

export default PanelPairSection
