import { Translation } from 'react-i18next'
import takeRight from 'lodash/takeRight'
import styled, { css } from 'styled-components'

import { colors } from 'bl-common/src/constants/colors'
import { modularScale } from 'bl-common/src/constants/sizes'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { IWaterQualityEntry } from 'bl-common/src/generated/contentful'
import { Appear } from 'bl-common/src/units/Appear'
import { Section } from 'bl-common/src/units/Section/Section'
import { between } from 'bl-common/src/utils/between'
import { media } from 'bl-common/src/utils/media'
import { formatDateInUTC } from 'bl-utils/src/formatting/formatDate'

import { getSectionProps } from '../../../utils/getSectionProps'

type EntryRowProps = {
  entry: IWaterQualityEntry
  index: number
  language: any
}

const Content = styled.div``

const Table = styled.table`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme: { spacing } }) => `${spacing[2.5]}`};
`
const TableRow = styled.tr`
  display: flex;
  margin: 0 -1px;
`
const TableHeader = styled.thead`
  width: 100%;
`
const TableBody = styled.tbody`
  width: 100%;
`
const HeaderCell = styled.th`
  align-items: center;
  color: ${colors.dark};
  display: flex;
  font-size: ${between(16 / modularScale, 16)};
  font-weight: bold;
  flex: 1;
  justify-content: center;
  margin: 1px;
  padding: ${({ theme: { spacing } }) => `${spacing[1]} ${spacing[1]}`};
  text-transform: uppercase;

  &:first-child {
    justify-content: flex-start;
  }

  ${media.md(css`
    padding: ${({ theme: { spacing } }) => `${spacing[1]} ${spacing[2]}`};
  `)}
`
const Cell = styled.td`
  align-items: center;
  color: ${colors.white};
  display: flex;
  flex: 1;
  font-size: ${between(14 / modularScale, 14)};
  justify-content: center;
  margin: 1px;
  padding: ${({ theme: { spacing } }) => `${spacing[1]}`};

  &:first-child {
    justify-content: flex-start;
  }

  ${media.md(css`
    padding: ${({ theme: { spacing } }) => `${spacing[2]} ${spacing[2]}`};
  `)}
`

const Dot = styled.span`
  border-radius: 50%;
  height: ${between(16 / modularScale, 16)};
  width: ${between(16 / modularScale, 16)};
`

const Legend = styled.ul`
  border-top: 0.5px solid #cbcbcb;
  display: flex;
  flex-wrap: wrap;
  padding-top: ${({ theme: { spacing } }) => `${spacing[2.5]}`};
`

const LegendItem = styled.li`
  align-items: center;
  display: flex;
  margin-bottom: ${({ theme: { spacing } }) => `${spacing[1]}`};
  margin-right: ${({ theme: { spacing } }) => `${spacing[7]}`};

  &:last-child {
    margin-right: 0;
  }
`

const LegendLabel = styled.div`
  font-size: ${between(14 / modularScale, 14)};
  font-weight: bold;
  margin-left: ${({ theme: { spacing } }) => `${spacing[1]}`};
  max-width: 237px;
  text-transform: uppercase;
`

const legendColors = [colors.brightGreen, colors.orange, colors.deepRed]

const getColor = (min, max, value) => {
  if (value < min) {
    return colors.brightGreen
  } else if (value > max) {
    return colors.deepRed
  }
  return colors.orange
}

const EntryRow = ({
  entry: {
    fields: { date, faecal, totalColiform, faecalStreptococci },
  },
  language,
  index,
}: EntryRowProps) => {
  return (
    <TableRow
      style={{
        backgroundColor: index % 2 === 0 ? colors.lightGrey : colors.white,
      }}
    >
      <Cell
        style={{
          color: colors.dark,
        }}
      >
        {formatDateInUTC(date, 'PP', language)}
      </Cell>
      <Cell>
        <Dot style={{ backgroundColor: getColor(500, 10000, totalColiform) }} />
      </Cell>
      <Cell>
        <Dot style={{ backgroundColor: getColor(100, 2000, faecal) }} />
      </Cell>
      <Cell>
        <Dot
          style={{ backgroundColor: getColor(100, 150, faecalStreptococci) }}
        />
      </Cell>
    </TableRow>
  )
}

const WaterQualityReportSection = props => {
  const {
    heading,
    multilineSubheading,
    centerText,
    reportTableHeadings,
    entries,
    legendItems,
    config,
  } = getSectionProps(props)
  const newestEntries = takeRight(entries, 4).reverse()

  return (
    <Translation>
      {({ i18n }: any) => (
        <Section
          config={config}
          top={{ xs: 4, md: 6 }}
          bottom={{ xs: 2, md: 6 }}
        >
          <Appear observer>
            {heading && (
              <Appear>
                <Type
                  as="h2"
                  preset="headlineMedium"
                  textAlign={centerText ? 'center' : 'left'}
                  bottom={{
                    xs: multilineSubheading ? 1 : 2,
                  }}
                >
                  {heading}
                </Type>
              </Appear>
            )}
            {multilineSubheading && (
              <Appear>
                <Type
                  preset="subtitle"
                  bottom={{ xs: 4, md: 5 }}
                  maxWidth={1000}
                  textAlign={centerText ? 'center' : 'left'}
                  style={centerText ? { margin: '0 auto' } : {}}
                  multiline
                >
                  {multilineSubheading}
                </Type>
              </Appear>
            )}
          </Appear>
          <Content>
            <Table>
              <TableHeader>
                <TableRow>
                  {reportTableHeadings.map(header => (
                    <HeaderCell key={header.sys.id}>
                      {header.fields.item}
                    </HeaderCell>
                  ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                {newestEntries.map((entry, index) => (
                  <EntryRow
                    key={index}
                    entry={entry as IWaterQualityEntry}
                    index={index}
                    language={i18n && i18n.language}
                  />
                ))}
              </TableBody>
            </Table>
          </Content>
          <Legend>
            {legendItems.map((item, index) => (
              <LegendItem key={item.fields.item}>
                <Dot style={{ backgroundColor: legendColors[index] }} />
                <LegendLabel>{item.fields.item}</LegendLabel>
              </LegendItem>
            ))}
          </Legend>
        </Section>
      )}
    </Translation>
  )
}

export default WaterQualityReportSection
