import get from 'lodash/get'

import { Type } from 'bl-common/src/elements/Typography/Typography'
import { Appear } from 'bl-common/src/units/Appear'
import { CardCarousel } from 'bl-common/src/units/CardCarousel/CardCarousel'
import { Section } from 'bl-common/src/units/Section/Section'

import { cardFormatters } from '../../../utils/formatters'

const widths = {
  small: {
    from: 250,
    to: 310,
  },
  medium: {
    from: 335,
    to: 425,
  },
  large: {
    from: 375,
    to: 465,
  },
}

export const CarouselSection = ({
  section: {
    fields: { heading, multilineSubheading, carousel, config },
  },
}) => {
  const cards = get(carousel, 'fields.cards')
  const formattedCards =
    cards &&
    cards.map(card => {
      const formatter = cardFormatters[get(card, 'sys.contentType.sys.id')]
      return formatter && formatter(card)
    })

  const width = widths[get(carousel, 'fields.cardWidth', 'small')]
  return (
    <Section
      config={config}
      top={{ xs: 4, md: 6 }}
      bottom={{ xs: 2, md: 6 }}
      hideOverflowX
    >
      <Appear observer>
        {heading && (
          <Appear>
            <Type as="h2" preset="headlineLarge" bottom={{ xs: 0.5, md: 1 }}>
              {heading}
            </Type>
          </Appear>
        )}
        {multilineSubheading && (
          <Appear>
            <Type
              preset="subtitle"
              bottom={{ xs: 2, md: 5 }}
              maxWidth={780}
              multiline
            >
              {multilineSubheading}
            </Type>
          </Appear>
        )}
        <CardCarousel
          cards={formattedCards || get(carousel, 'fields.images')}
          minItemWidthFrom={width.from}
          minItemWidthTo={width.to}
          id={get(carousel, 'sys.id')}
        />
      </Appear>
    </Section>
  )
}

export default CarouselSection
