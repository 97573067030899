import { stripIndent } from 'common-tags'
import { parse } from 'date-fns/parse'

import { formatDateInUTC } from 'bl-utils/src/formatting/formatDate'

import i18n from './i18n'

type ValidatorError = {
  kids?: string
  time?: string
  first?: string
  last?: string
}

type Validator = (
  value:
    | {
        time?: unknown
        date?: unknown
        kids?: unknown
        first?: unknown
        last?: unknown
        address?: unknown
        city?: unknown
        country?: unknown
      }
    | string,
  object: {
    type: string
    isRequired: unknown
    hideChildPicker: unknown
    name: unknown
  },
  locale: 'is' | 'en'
) => ValidatorError | false | string | (string | object)[]

const validators: Validator[] = [
  // reservation
  (value = '', { type, isRequired, hideChildPicker }, locale) => {
    if (type === 'reservation' && typeof value === 'object') {
      if (!isRequired) {
        return false
      }

      let errors: ValidatorError = {}

      if (!value) {
        return {
          time: i18n.t('reservationDateAndTime', { lng: locale }),
          kids: i18n.t('reservationKids', { lng: locale }),
        }
      }

      // check if both time and date have been selected
      if (!value.time && !value.date) {
        errors = {
          ...errors,
          time: i18n.t('reservationDateAndTime', { lng: locale }),
        }
      } else if (!value.time) {
        errors = { ...errors, time: i18n.t('reservationTime', { lng: locale }) }
      } else if (!value.date) {
        errors = {
          ...errors,
          time: i18n.t('reservationDate', { lng: locale }),
        }
      }

      // check if number of children has been selected
      if (!hideChildPicker && !value.kids) {
        errors = { ...errors, kids: i18n.t('reservationKids', { lng: locale }) }
      }

      return errors.kids || errors.time ? errors : false
    }
  },

  // name
  (value = '', { type, isRequired }, locale) => {
    if (type === 'name' && typeof value === 'object') {
      if (!isRequired) {
        return false
      }

      let errors: ValidatorError = {}

      if (!value) {
        return {
          first: i18n.t('nameFirstAndLast', { lng: locale }),
        }
      }

      // check if both time and date have been selected
      if (!value.first && !value.last) {
        errors = {
          ...errors,
          first: i18n.t('nameFirstAndLast', { lng: locale }),
        }
      } else if (!value.first) {
        errors = {
          ...errors,
          first: i18n.t('nameFirst', { lng: locale }),
        }
      } else if (!value.last) {
        errors = {
          ...errors,
          last: i18n.t('nameLast', { lng: locale }),
        }
      }

      return errors.first || errors.last ? errors : false
    }
  },

  // isRequired
  (value, { isRequired, name }, locale) =>
    isRequired && !value && i18n.t('required', { lng: locale, name }),

  // name required
  (value, { isRequired, type, name }, locale) =>
    type === 'name' &&
    isRequired &&
    !value &&
    i18n.t('required', { lng: locale, name }),

  // address required
  (value, { isRequired, type, name }, locale) =>
    type === 'address' &&
    typeof value === 'object' &&
    isRequired &&
    (!value || !value.address || !value.city || !value.country) &&
    i18n.t('required', { lng: locale, name }),
  // strings
  (value, { type, name }, locale) =>
    ['email', 'date', 'text', 'textarea', 'radios'].indexOf(type) >= 0 &&
    value != null &&
    typeof value !== 'string' &&
    i18n.t('inputType', { lng: locale, name }),

  (value, { type, name }, locale) =>
    type === 'checkbox' &&
    value != null &&
    typeof value !== 'boolean' &&
    i18n.t('email', { lng: locale, name }),

  // email
  (value = '', { type, name }, locale) =>
    typeof value === 'string' &&
    type === 'email' &&
    value &&
    !value.match(/^.+@.+\..+$/) &&
    i18n.t('email', { lng: locale, name }),

  // date
  (value = '', { type, name }, locale) =>
    typeof value === 'string' &&
    type === 'date' &&
    value &&
    !value.match(/^\d{4}-\d{2}-\d{2}$/) &&
    i18n.t('date', { lng: locale, name }),
]

export const formatForKlaviyo = (form, values) => {
  const data = []

  form.fields.map(({ key, type }) => {
    const value = values[key]

    switch (type) {
      case 'name': {
        data.push({
          first_name: value.first,
          last_name: value.last,
        })
        break
      }
      case 'email': {
        data.push({
          email: value,
        })
        break
      }
      case 'reservation': {
        data.push({
          reservation_date: formatDateInUTC(
            parse(
              `${value.date} ${value.time}`,
              'dd-MM-yyyy HH:mm',
              new Date()
            ),
            'yyyy-MM-dd HH:mm:ss'
          ),
          reservation_time: value.time,
          is_reservation: true,
        })
        break
      }
    }
  })

  return data
}

export const clean = (form, values) =>
  form.fields.map(({ name, key, type, options }) => {
    let value = values[key]
    let receiver

    switch (type) {
      case 'reservation':
        value = value ? `Date: ${value.date}, Time: ${value.time}` : ''
        break
      case 'name':
        value = value
          ? `${(value.first || '').trim()} ${(value.last || '').trim()}`.trim()
          : ''
        break
      case 'address':
        value = value
          ? stripIndent`
          ${(value.address || '').trim()}
          ${(value.city || '').trim()} ${(value.state || '').trim()} ${(
            value.zip || ''
          ).trim()}
          ${(value.country || '').trim()}
        `
              .replace(/\s*\n\s*/g, '\n')
              .replace(/ {2,}/g, ' ')
          : ''
        break
      case 'checkbox':
        value = value ? 'yes' : 'no'
        break
      case 'radio':
      case 'select': {
        const option = options.find(option => option.value === value)
        value = (option && option.label) || ''
        receiver = option && option.receiver
        break
      }
      case 'file':
        value = (value || []).join(', ')
        break
      default:
        value = `${value}`.trim()
        break
    }
    return { value, key, name, receiver }
  })

export const validate = (form, values, locale) =>
  form.fields.reduce((errors, field) => {
    const key = field.key
    const value = values[key]

    let error
    validators.some(validator => {
      error = validator(value, field, locale)

      return error
    })

    if (error) {
      errors[key] = error
    }

    return errors
  }, {})
