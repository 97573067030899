import get from 'lodash/get'
import styled, { css } from 'styled-components'

import { colors } from '../../constants/colors'
import { Amount } from '../../elements/Amount'
import { BackgroundLink } from '../../elements/BackgroundLink'
import { Type } from '../../elements/Typography/Typography'
import { getImageUrl } from '../../units/SmartImage/getImageUrl'

type GradientProps = {
  color1?: any
  color2?: any
  degrees?: any
}

const CardWrapper = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
  right: 16px;
  bottom: 16px;
`

const Gradient = styled.div<GradientProps>`
  margin: 0 16px;
  height: 100%;
  position: relative;

  ${({ color1, color2, degrees }) =>
    color1 &&
    color2 &&
    css`
      background: linear-gradient(
        ${degrees || 'to right'},
        ${color1},
        ${color2}
      );
    `};
`

const CardContent = styled.div`
  position: relative;
  background: ${colors.white};
  padding: ${({ theme }) =>
    `${theme.spacing[1.5]} ${theme.spacing[1]} ${theme.spacing[2]}`};
  flex: 1;
  display: flex;
  flex-direction: column;
`

const BackgroundImage = styled.div`
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;

  &::before {
    content: '';
    display: block;
    padding-bottom: 125%;
  }
`

const PriceType = styled(({ ...props }) => (
  <Type preset="text" case="uppercase" weight="medium" {...props} />
))`
  margin-top: auto;
`

export const UpgradeCard = ({
  title,
  description,
  url,
  image,
  backgroundPosition = 'center',
  price,
  priceRef,
  priceFormat,
  gradient,
  linkComponent,
  noPriceText = 'Reserve now',
}) => {
  return (
    <Gradient
      color1={get(gradient, 'fields.color1', gradient.color1)}
      color2={get(gradient, 'fields.color2', gradient.color2)}
      degrees={get(gradient, 'fields.degrees', gradient.degrees)}
    >
      {url && (
        <BackgroundLink
          linkComponent={linkComponent}
          to={url}
          aria-hidden="true"
          tabIndex={-1}
          zIndex={4}
        />
      )}
      <CardWrapper key={title}>
        <BackgroundImage
          style={{
            backgroundImage: `url(${getImageUrl(image, { width: 1200 })})`,
            backgroundPosition,
          }}
        />
        <CardContent>
          {title && (
            <Type
              preset="label"
              case="uppercase"
              weight="bold"
              bottom={{ xs: 1, md: 1 }}
            >
              {title}
            </Type>
          )}
          {description && (
            <Type preset="textSmall" bottom={{ xs: 2.5, md: 2.5 }}>
              {description}
            </Type>
          )}
          {priceRef || price ? (
            <PriceType>
              <Amount value={priceRef} format={priceFormat} fallback={price} />
            </PriceType>
          ) : (
            <PriceType>{noPriceText}</PriceType>
          )}
        </CardContent>
      </CardWrapper>
    </Gradient>
  )
}
