import { BLOCKS } from '@contentful/rich-text-types'
import { darken } from 'polished'
import styled, { css } from 'styled-components'

import { gradients } from 'bl-common/src/constants/gradients'
import { modularScale } from 'bl-common/src/constants/sizes'
import { zIndex } from 'bl-common/src/constants/zIndex'
import { Amount } from 'bl-common/src/elements/Amount'
import { Button } from 'bl-common/src/elements/Button/Button'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import {
  IGradientFields,
  ITopicGradientSectionFields,
} from 'bl-common/src/generated/contentful'
import {
  BulletList,
  ButtonLink,
  RichTextListItem,
} from 'bl-common/src/richText/RichTextBlocks'
import { RichTextRenderer } from 'bl-common/src/richText/RichTextRenderer'
import { ResponsiveParallax } from 'bl-common/src/units/ResponsiveParallax'
import { SmartImage } from 'bl-common/src/units/SmartImage/SmartImage'
import { between } from 'bl-common/src/utils/between'
import { media } from 'bl-common/src/utils/media'

type TopicGradientSectionProps = ITopicGradientSectionFields & {
  gradient?: IGradientFields
}

type ImageProps = {
  image?: string
}

type ImageDecoratorProps = {
  gradient?: any
}

type ContentProps = {
  textPosition?: any
}

const Image = styled.div<ImageProps>`
  background-size: cover;
  margin-top: 80px;
  position: relative;
  width: 100%;
  z-index: ${zIndex.above};
  background-position: center;
  background-image: ${({ image }) => `url(${image})`};

  ${media.mlg(css`
    margin-top: ${({ theme }) => theme.spacing[3]};
  `)};

  &::before {
    content: '';
    padding-top: 66.666%;
    display: block;

    ${media.mlg(css`
      padding-top: 133%;
    `)};
  }

  ${media.mlg(css`
    margin-top: 0;
  `)};
`

const ImageDecorator = styled(ResponsiveParallax)<ImageDecoratorProps>`
  position: absolute;
  top: ${between(-32 / modularScale, -32)};
  width: 100%;
  right: ${between(-64 / modularScale, -64)};
  opacity: 0.5;

  ${({
    gradient = {
      fields: {
        color1: gradients.supportSilver[0],
        color2: gradients.supportSilver[1],
      },
    },
  }) => {
    const { color1, color2 } = gradient.fields
    return css`
      background: linear-gradient(
        ${darken(0.05, color1)},
        ${darken(0.05, color2)}
      );
    `
  }};

  &::before {
    content: '';
    padding-top: 66.666%;
    display: block;

    ${media.mlg(css`
      padding-top: 133%;
    `)};
  }
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const ImageContent = styled.div`
  flex: 0 0 100%;

  ${media.mlg(css`
    margin-left: ${between(64 / modularScale, 64)};
    margin-right: ${between(64 / modularScale, 64)};
    flex: 0 1 50%;
  `)};
`

const ImageInner = styled.div`
  position: relative;
`

const TextContent = styled.div`
  flex: 0 0 100%;
  padding-right: ${between(64 / modularScale, 64)};

  ${media.mlg(css`
    padding-left: ${between(64 / modularScale, 64)};
    flex: 0 1 50%;
    margin-right: auto;
  `)};
`

const Content = styled.div<ContentProps>`
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  ${media.mlg(css`
    flex-wrap: nowrap;
  `)}

  ${props =>
    props.textPosition === 'Right side' &&
    css`
      ${ImageContent} {
        ${media.mlg(css`
          order: -1;
        `)};
      }
      ${Image} {
        ${media.mlg(css`
          left: 0;
        `)};
      }
      ${ImageDecorator} {
        ${media.md(css`
          left: ${between(-32 / modularScale, -32)};
        `)};
      }
    `};
`

const Prices = styled.dl`
  width: 100%;
  display: flex;
  margin: 0;
  padding: ${({ theme }) => theme.spacing[1.5]};
  &:nth-of-type(odd) {
    background: rgba(0, 0, 0, 0.03);
  }
  ${media.lg(css`
    max-width: ${between(385 / modularScale, 385)};
  `)};
`
const Key = styled.dt`
  flex: 0 1 auto;
  margin-right: 1rem;
  max-width: 70%;
`
const Value = styled.dd`
  text-align: right;
  margin-left: auto;
  flex: 1 0 auto;
`

const Quote = styled.blockquote`
  padding-left: ${({ theme }) => theme.spacing[2]};
`

const Markdown = styled.div`
  padding-bottom: 40px;

  ${media.md(css`
    padding-bottom: ${between(64 / modularScale, 64)};
  `)};

  li > p {
    padding-bottom: 0px;
  }
`

const documentOptions = {
  renderNode: {
    [BLOCKS.HEADING_2]: (_node, children) => (
      <Type preset="headlineMedium" as="h2" bottom={{ xs: 1, md: 2.5 }}>
        {children}
      </Type>
    ),
    [BLOCKS.HEADING_3]: (_node, children) => (
      <Type preset="headlineSmall" as="h3" bottom={{ xs: 1, md: 1 }}>
        {children}
      </Type>
    ),
    [BLOCKS.PARAGRAPH]: (_node, children) => (
      <Type preset="textExtraLarge" bottom={{ xs: 1 }}>
        {children}
      </Type>
    ),
    [BLOCKS.QUOTE]: (_node, children) => <Quote>{children}</Quote>,
    [BLOCKS.EMBEDDED_ENTRY]: node => {
      const { sys, fields } = node.data.target
      switch (sys.contentType.sys.id) {
        case 'ctaButton':
          return fields.displayAsLink ? (
            <ButtonLink
              textColor={fields.textColor}
              to={fields.link}
              emailTrigger={fields.emailTrigger}
              weight="bold"
              paddingSize="small"
            >
              {fields.text}
            </ButtonLink>
          ) : (
            <Button
              textColor={fields.textColor}
              paddingSize={fields.paddingSize}
              preset={fields.color}
              to={fields.link}
              maxWidth={385}
              top={{ xs: 1 }}
              emailTrigger={fields.emailTrigger}
            >
              {fields.text}
            </Button>
          )
        case 'list':
          return (
            <BulletList>
              {fields.listItems.map(item => (
                <RichTextListItem {...item.fields} key={item.fields.item}>
                  {item.fields.item}
                </RichTextListItem>
              ))}
            </BulletList>
          )
        default:
          return null
      }
    },
  },
}

export const TopicGradientSection = ({
  title,
  textContent,
  image,
  textPosition,
  pricing,
  gradient,
}: TopicGradientSectionProps) => (
  <Container>
    <Content textPosition={textPosition}>
      <TextContent>
        <Type
          as="h2"
          preset="headlineLarge"
          bottom={{ xs: 1, md: 2 }}
          top={{ xs: 2, md: 4 }}
        >
          {title}
        </Type>
        <Markdown>
          {!!textContent && (
            <RichTextRenderer
              document={textContent}
              customOptions={documentOptions}
            />
          )}
        </Markdown>

        {pricing?.map(price => {
          const { value, priceRef } = price.fields

          return (
            <Prices key={price.fields.key}>
              <Key>{price.fields.key}</Key>
              <Value>
                <Amount
                  value={priceRef?.fields}
                  format={priceRef?.fields.priceFormat}
                  fallback={value}
                />
              </Value>
            </Prices>
          )
        })}
      </TextContent>
      <ImageContent>
        <ImageInner>
          <SmartImage image={image}>
            {image => <Image image={image} />}
          </SmartImage>
          <ImageDecorator gradient={gradient} from={0} to={-5} disabledMobile />
        </ImageInner>
      </ImageContent>
    </Content>
  </Container>
)
