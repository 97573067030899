import { darken } from 'polished'
import styled, { css } from 'styled-components'

import { breakpoints } from 'bl-common/src/constants/breakpoints'
import { gradients } from 'bl-common/src/constants/gradients'
import { modularScale } from 'bl-common/src/constants/sizes'
import { zIndex } from 'bl-common/src/constants/zIndex'
import { Button } from 'bl-common/src/elements/Button/Button'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { SmartImage } from 'bl-common/src/units/SmartImage/SmartImage'
import { between } from 'bl-common/src/utils/between'
import { media } from 'bl-common/src/utils/media'

type SidebarProps = {
  image?: string
}

type ImageProps = {
  image?: string
}

type ImageDecoratorProps = {
  gradient?: any
}

const Container = styled.div`
  display: flex;
`

const Content = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex: 1 1 56%;

  ${media.md(css`
    padding: ${between(94 / modularScale, 94)} ${between(96 / modularScale, 96)}
      ${between(131 / modularScale, 131)} 0;
  `)};
`

const Sidebar = styled.aside<SidebarProps>`
  position: relative;
  flex: 1 0 35%;
  display: none;
  @media (min-width: ${breakpoints.md}px) and (orientation: landscape) {
    display: block;
  }
  &::before {
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    ${({ image }) => css`
      background-image: url(${image});
      background-size: cover;
      background-repeat: no-repeat;
    `};
  }
`

const Image = styled.div<ImageProps>`
  position: relative;
  display: none;
  width: 100%;
  height: 100%;
  z-index: ${zIndex.above};
  @media (min-width: ${breakpoints.md}px) and (orientation: landscape) {
    display: block;
  }
  &::before {
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    ${({ image }) => css`
      background-image: url(${image});
      background-size: cover;
      background-repeat: no-repeat;
    `};
  }
`

const StyledButton = styled(Button)`
  margin-top: auto;
  display: block;
`

const ImageDecorator = styled.div<ImageDecoratorProps>`
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: ${({ theme }) => theme.spacing[-2]};
  right: ${({ theme }) => theme.spacing[-2]};
  opacity: 0.5;

  ${({
    gradient = {
      fields: {
        color1: gradients.g6[0],
        color2: gradients.g6[1],
      },
    },
  }) => {
    const { color1, color2 } = gradient.fields
    return css`
      background: linear-gradient(
        ${darken(0.05, color1)},
        ${darken(0.05, color2)}
      );
    `
  }};
`

export const CallToAction = ({
  title,
  description,
  image,
  gradient,
  buttonUrl,
  buttonText = 'Wine List',
}) => (
  <Container>
    <Content>
      <Type preset="headline" bottom={{ xs: 0.5, md: 1 }}>
        {title}
      </Type>
      <Type
        multiline
        preset="subtitle"
        bottom={{ xs: 3, md: 3 }}
        maxWidth={555}
      >
        {description}
      </Type>
      {buttonUrl && (
        <StyledButton paddingSize="large" to={buttonUrl} minWidth={264}>
          {buttonText}
        </StyledButton>
      )}
    </Content>
    <Sidebar>
      <SmartImage image={image}>
        {imageUrl => <Image image={imageUrl} />}
      </SmartImage>
      <ImageDecorator gradient={gradient} />
    </Sidebar>
  </Container>
)
