/* eslint-disable react/display-name */

import { ComparisonCard } from '../../cards/ComparisonCard/ComparisonCard'
import { CopyCard } from '../../cards/CopyCard/CopyCard'
import { CoverCard } from '../../cards/CoverCard/CoverCard'
import { ImageCard } from '../../cards/ImageCard/ImageCard'
import { PackageCard } from '../../cards/PackageCard/PackageCard'
import { SplitCard } from '../../cards/SplitCard/SplitCard'
import { UpgradeCard } from '../../cards/UpgradeCard/UpgradeCard'
import { Carousel } from '../../units/Carousel/Carousel'
import { LightBoxProvider } from '../LightBox/LightBoxProvider'
import { Tag } from '../Tags/Tag'

const availableCardComponents = {
  amenityCard: card => (
    <CoverCard {...card} minHeight={card.height === 'large' ? 590 : 390} />
  ),
  comparisonCard: card => <ComparisonCard {...card} />,
  splitCard: card => <SplitCard {...card} />,
  suiteCard: card => <SplitCard {...card} tall />,
  imageCard: card => <ImageCard {...card} />,
  image: card => (
    <ImageCard
      image={card}
      lightBoxImage={card}
      imageSize="small"
      backgroundPosition="center"
    />
  ),
  copyCard: card => <CopyCard {...card} />,
  packageCard: card => <PackageCard {...card} />,
  upgradeCard: props => <UpgradeCard {...props} />,
  categoryTag: props => <Tag {...props} />,
}

export const CardCarousel = ({
  id = undefined,
  cards,
  minItemWidthFrom,
  minItemWidthTo,
  onBookClick = undefined,
  endWidth = undefined,
  initialCard = undefined,
  linkComponent = undefined,
  cardComponent = undefined,
}) => {
  return cards ? (
    <LightBoxProvider>
      <Carousel
        scrollKey={id}
        minItemWidthFrom={minItemWidthFrom}
        minItemWidthTo={minItemWidthTo}
        key={id}
        endWidth={endWidth}
        initialItem={initialCard} // This is partly causing the arrow bug (Arrows are showing in desktop when there are no extra pages to flip to) (See https://app.asana.com/0/1112659052496224/1202488136438990)
      >
        {cards.map((card, index) => {
          const cardType = card.type || 'image'
          const Component = cardComponent || availableCardComponents[cardType]
          return (
            <Component
              {...card}
              onBookClick={onBookClick ? () => onBookClick(index) : undefined}
              linkComponent={linkComponent}
              key={card.id || card.sys.id}
            />
          )
        })}
      </Carousel>
    </LightBoxProvider>
  ) : null
}
