import get from 'lodash/get'
import styled, { css } from 'styled-components'

import { colors } from 'bl-common/src/constants/colors'
import { ScrollArrow } from 'bl-common/src/elements/Arrow/Arrow'
import { Button } from 'bl-common/src/elements/Button/Button'
import { Appear } from 'bl-common/src/units/Appear'
import { Section } from 'bl-common/src/units/Section/Section'
import { media } from 'bl-common/src/utils/media'

import { formatButton } from '../../../utils/formatters'
import { getSectionProps } from '../../../utils/getSectionProps'
import { HeroTextScroller } from '../../HeroTextScroller'
import { VideoHero } from '../../VideoHero'

type ScrollArrowContainerProps = {
  isVisible?: boolean
}

export const ScrollArrowContainer = styled.div<ScrollArrowContainerProps>`
  opacity: 0;
  transform: translateY(-100px);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 100px;
  display: flex;
  justify-content: center;
  transition: opacity 1000ms;

  ${({ isVisible }) =>
    isVisible &&
    css`
      opacity: 1;
      transform: translateY(0);
    `};
`

export const CtaButton = styled(Button)`
  bottom: ${({ theme }) => theme.spacing[9]};
  background: rgba(0, 0, 0, 0.4);
  cursor: pointer;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 60%;

  ${media.md(css`
    bottom: ${({ theme }) => theme.spacing[10]};
    top: auto;
    width: auto;
  `)}

  ${media.lg(css`
    bottom: ${({ theme }) => theme.spacing[8]};
  `)}
`

const VideoHeroSection = props => {
  const {
    videoHero,
    textLoop,
    withScrollArrow,
    color = 'light',
    button,
    config,
  } = getSectionProps(props)

  const textColors = {
    light: colors.white,
    dark: colors.dark,
    blue: colors.deepBlue,
  }

  const ctaButton = button && formatButton(button)

  return (
    <Section bottom={{ xs: 2, md: 4 }} config={config}>
      <VideoHero
        video={get(videoHero, 'fields.video')}
        fallback={get(videoHero, 'fields.videoFallback')}
        videoHq={get(videoHero, 'fields.videoHq')}
        videoMobile={get(videoHero, 'fields.videoMobile')}
        poster={get(videoHero, 'fields.poster')}
        posterMobile={get(videoHero, 'fields.posterMobile')}
      >
        {textLoop && (
          <Appear observer>
            <HeroTextScroller
              sentences={textLoop.fields.text}
              color={textColors[color]}
            >
              {isComplete => (
                <ScrollArrowContainer isVisible={isComplete && withScrollArrow}>
                  <ScrollArrow />
                </ScrollArrowContainer>
              )}
            </HeroTextScroller>
          </Appear>
        )}
        {!textLoop && withScrollArrow && (
          <ScrollArrowContainer
            isVisible={true}
            style={{ color: textColors[color] }}
          >
            <ScrollArrow />
          </ScrollArrowContainer>
        )}
        {button && <CtaButton {...ctaButton}>{ctaButton.text}</CtaButton>}
      </VideoHero>
    </Section>
  )
}

export default VideoHeroSection
