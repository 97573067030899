import get from 'lodash/get'
import styled, { css } from 'styled-components'
import { map } from 'styled-components-breakpoint'

import { isEdge } from 'bl-utils/src/isEdge'

import { colors } from '../../constants/colors'
import { durations } from '../../constants/durations'
import { modularScale } from '../../constants/sizes'
import { zIndex } from '../../constants/zIndex'
import { Link } from '../../elements/Link'
import { Type } from '../../elements/Typography/Typography'
import { SmartImage } from '../../units/SmartImage/SmartImage'
import { between } from '../../utils/between'
import { media } from '../../utils/media'

type CoverCardProps = {
  title?: string
  subtitle?: string
  sizing?: {
    width: string
    height: string
  }
  media?: any
  fallbackMedia?: any
  minHeight?: number
  bottomSpacing?: number
  gradientOverlay?: any
  grayscale?: boolean
  link?: string
}

type ComponentProps = {
  title?: string
  subtitle?: string
  media?: any
  link?: string
  sizing?: {
    width: string
    height: string
  }
  gradientOverlay?: any
  grayscale?: boolean
  minHeight?: number
  bottomSpacing?: number
  fallback?: any
}

type BackgroundImageProps = {
  grayscale?: boolean
}

type EffectProps = {
  gradientOverlay?: any
}

type VideoTagProps = {
  video?: any
  fallback?: any
}

const Title = styled(Type)`
  font-weight: 600;
  position: relative;
  z-index: ${zIndex.above};
`

const Subtitle = styled(Type)`
  font-weight: 300;
  position: relative;
  z-index: ${zIndex.above};
`

const BackgroundImage = styled.div<BackgroundImageProps>`
  position: absolute;
  left: -1px;
  top: -1px;
  right: -1px;
  bottom: -1px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: transform ${durations.long}ms;

  ${({ grayscale }) =>
    grayscale &&
    css`
      @supports (mix-blend-mode: multiply) {
        filter: grayscale(50%);
        opacity: 0.75;

        &:hover {
          opacity: 1;
        }
      }
    `}
`

const Container = styled(
  ({ sizing, media, minHeight, bottomSpacing, to, ...rest }) => {
    return to ? <Link to={to} {...rest} /> : <div {...rest} />
  }
)`
  color: ${colors.white};
  cursor: ${({ to }) => (to ? 'pointer' : 'default')};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
  position: relative;
  width: 100%;

  ${({ minHeight = 390 }) =>
    map(minHeight, val => `min-height: ${between(val / modularScale, val)}`)};	
}

  ${function ({ sizing = { width: null } }) {
    if (sizing.width === 'half') {
      return css`
        width: calc(50% - 8px);

        ${media.md(css`
          width: calc(50% - 22px);
        `)};
      `
    }
    if (sizing.width === 'full') {
      return css`
        width: 100%;
      `
    }
  }};
  
  ${({ bottomSpacing }) => css`
    margin-bottom: ${({ theme }) => theme.spacing[bottomSpacing]};
  `};


  ${media.md(css`
    min-height: ${(props: any) => props.sizing && props.sizing.height};

    &:hover {
      ${BackgroundImage} {
        transform: scale(1.05);
      }
    }
  `)};
`

const Effect = styled.div<EffectProps>`
  @supports (mix-blend-mode: multiply) {
    mix-blend-mode: multiply;
    position: absolute;
    left: -1px;
    top: -1px;
    right: -1px;
    bottom: -1px;
    z-index: ${zIndex.above};
    ${({ gradientOverlay }) =>
      gradientOverlay &&
      css`
        background: linear-gradient(
          ${get(
            gradientOverlay,
            'fields.degrees',
            gradientOverlay.degrees || '90deg'
          )},
          ${get(gradientOverlay, 'fields.color1', gradientOverlay.color1)},
          ${get(gradientOverlay, 'fields.color2', gradientOverlay.color2)}
        );
      `};
  }
`
const VideoTag = styled.video.attrs(({ video, fallback }: VideoTagProps) => ({
  src: get(video, 'fields.file.url', video),
  poster: get(video, 'fields.file.url', fallback),
  autoPlay: true,
  loop: true,
  muted: true,
  playsInline: true,
}))<VideoTagProps>`
  bottom: 0;
  height: 100%;
  left: 0;
  margin: auto;
  object-fit: cover;
  position: absolute;
  right: 0;
  top: 0;
  transition: transform ${durations.long}ms;
  width: 100%;
`

const VideoContainer = styled(Container)`
  overflow: hidden;

  &:hover {
    ${VideoTag} {
      transform: scale(1.05);
    }
  }
`

const Content = styled.div`
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: ${({ theme: { spacing } }) => ` ${spacing[2.5]}`};
  width: 100%;
`

const Image = ({
  title,
  subtitle,
  media,
  link,
  sizing,
  gradientOverlay,
  grayscale = true,
  minHeight,
  bottomSpacing,
}: ComponentProps) => (
  <SmartImage image={media} styles>
    {imageProps => (
      <Container
        to={link}
        bottomSpacing={bottomSpacing}
        sizing={sizing}
        minHeight={minHeight}
      >
        <Effect gradientOverlay={gradientOverlay} />
        <BackgroundImage {...imageProps} grayscale={grayscale} />
        <Content>
          <Title preset="textLarge" case="uppercase">
            {title}
          </Title>
          {subtitle && <Subtitle preset="text">{subtitle}</Subtitle>}
        </Content>
      </Container>
    )}
  </SmartImage>
)

const Video = ({
  title,
  subtitle,
  media,
  link,
  sizing,
  minHeight,
  fallback,
  bottomSpacing,
}: ComponentProps) => (
  <VideoContainer
    to={link}
    sizing={sizing}
    minHeight={minHeight}
    bottomSpacing={bottomSpacing}
  >
    <VideoTag video={media} fallback={fallback} />
    <Content>
      <Title preset="textLarge" case="uppercase">
        {title}
      </Title>
      {subtitle && <Subtitle preset="text">{subtitle}</Subtitle>}
    </Content>
  </VideoContainer>
)

export const CoverCard = ({
  media,
  fallbackMedia,
  ...rest
}: CoverCardProps) => {
  if (!media) return null
  const isVideo = get(media, 'fields.file.contentType', '').includes('video')
  const Component = isVideo && !isEdge ? Video : Image

  return (
    <Component
      media={isEdge && isVideo ? fallbackMedia : media}
      fallback={fallbackMedia}
      {...rest}
    />
  )
}
