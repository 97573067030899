import get from 'lodash/get'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { colors } from '../../constants/colors'
import { durations } from '../../constants/durations'
import { gradients } from '../../constants/gradients'
import { modularScale } from '../../constants/sizes'
import { zIndex } from '../../constants/zIndex'
import { Amount } from '../../elements/Amount'
import { Link } from '../../elements/Link'
import { Type } from '../../elements/Typography/Typography'
import { Appear } from '../../units/Appear'
import { StyledImage } from '../../units/SmartImage/StyledImage'
import { between } from '../../utils/between'
import { media } from '../../utils/media'

type ContentProps = {
  minHeight?: number
}

type GradientProps = {
  color1?: any
  color2?: any
  degrees?: any
}

const Content = styled.div<ContentProps>`
  bottom: 24px;
  box-shadow: 0 20px 20px -20px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  color: ${colors.midGrey};
  left: 24px;
  margin: auto;
  top: auto;
  padding: 40px 29px;
  position: absolute;
  right: 24px;
  text-align: center;
  z-index: ${zIndex.above};
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${({ minHeight }) =>
    minHeight &&
    css`
      min-height: ${between(minHeight / modularScale, minHeight)};
    `};

  &::before {
    background: ${colors.white};
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: ${zIndex.behind};
    transition: transform ${durations.short}ms;
  }

  ${media.md(css`
    bottom: ${({ theme }) => theme.spacing[2]};
    left: ${({ theme }) => theme.spacing[2]};
    right: ${({ theme }) => theme.spacing[2]};
    top: initial;
  `)};
`

const Gradient = styled.div<GradientProps>`
  padding-top: 50%;

  ${props =>
    props.color1 &&
    props.color2 &&
    css`
      background: linear-gradient(
        ${props.degrees || 'to right'},
        ${props.color1},
        ${props.color2}
      );
    `};
`

const BackgroundImage = styled(StyledImage)`
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &::before {
    content: '';
    display: block;
    padding-bottom: 75.15%;
  }
`

const Container = styled(({ tall, ...rest }) => <Link {...rest} />)`
  background-color: transparent;
  display: block;
  width: 100%;
  position: relative;
  z-index: 0;
  box-shadow: 0 20px 20px -20px rgba(0, 0, 0, 0.2);

  ${({ tall }) =>
    tall &&
    css`
      ${BackgroundImage} {
        &::before {
          padding-bottom: 121%;
        }
      }
      ${Gradient} {
        padding-top: 6rem;
      }
      ${Content} {
        padding: 32px;
      }
    `};

  ${media.md(css`
    &:hover {
      ${Content}::before {
        transform: scale(1.035);
      }
    }
  `)};
`

export const SplitCard = ({
  title,
  description,
  footNote,
  image,
  tall,
  gradient,
  link,
  priceRef,
  priceFormat,
}) => (
  <Appear>
    <Container to={link} tall={tall}>
      <BackgroundImage image={image} />
      <Gradient
        color1={get(gradient, 'fields.color1', gradient.color1)}
        color2={get(gradient, 'fields.color2', gradient.color2)}
        degrees={get(gradient, 'fields.degrees', gradient.degrees)}
      >
        <Content minHeight={!tall ? 200 : undefined}>
          <Type
            as="h3"
            size={{ xs: 16, md: 16 }}
            weight="bold"
            case="uppercase"
            bottom={{ xs: 0.5, md: 0.5 }}
          >
            {title}
          </Type>
          {description && !tall && (
            <Type preset="textSmall">{description}</Type>
          )}
          {(priceRef || footNote) && (
            <Type
              preset="label"
              weight="medium"
              bottom={!tall && { xs: 1, md: 1 }}
              style={{ whiteSpace: 'nowrap' }}
            >
              <Amount
                value={priceRef}
                format={priceFormat}
                fallback={footNote}
              />
            </Type>
          )}
        </Content>
      </Gradient>
    </Container>
  </Appear>
)

SplitCard.defaultProps = {
  gradient: {
    color1: gradients.supportSilver[0],
    color2: gradients.supportSilver[1],
    degrees: 'to right',
  },
  link: '/',
}

SplitCard.propTypes = {
  description: PropTypes.string,
  footNote: PropTypes.string,
  gradient: PropTypes.any.isRequired,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  link: PropTypes.string.isRequired,
  tall: PropTypes.bool,
  title: PropTypes.string.isRequired,
}
