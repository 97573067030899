import { ISectionIncludedListFields } from 'bl-common/src/generated/contentful'
import { IncludedList } from 'bl-common/src/units/IncludedList/IncludedList'
import { OffsetSection } from 'bl-common/src/units/Section/OffsetSection'

import { formatGradient } from '../../../utils/formatters'
import { getSectionProps } from '../../../utils/getSectionProps'

export const IncludedListSection = (props: any) => {
  const { backgroundGradient, config, offsetDirection, ...contentfulProps } =
    getSectionProps(props) as ISectionIncludedListFields

  const formattedGradient = formatGradient(backgroundGradient)

  return (
    <OffsetSection
      top={{ xs: 2, md: 4 }}
      bottom={{ xs: 2, md: 4 }}
      fullWidthMobile
      offsetDirection={offsetDirection}
      background={formattedGradient}
      config={config}
    >
      <IncludedList
        sectionIncludedList={{
          backgroundGradient,
          config,
          offsetDirection,
          ...contentfulProps,
        }}
      />
    </OffsetSection>
  )
}

export default IncludedListSection
