import { Asset } from 'contentful'
import styled, { css } from 'styled-components'

import { colors } from 'bl-common/src/constants/colors'
import { durations } from 'bl-common/src/constants/durations'
import { zIndex } from 'bl-common/src/constants/zIndex'
import { Amount } from 'bl-common/src/elements/Amount'
import { LinkArrow } from 'bl-common/src/elements/Arrow/Arrow'
import { ContentfulImage } from 'bl-common/src/elements/ContentfulImage'
import { FocalImage } from 'bl-common/src/elements/FocalImage'
import { Link } from 'bl-common/src/elements/Link'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import {
  IImageWithFocalPoint,
  IProductPanelCardFields,
} from 'bl-common/src/generated/contentful'
import { media } from 'bl-common/src/utils/media'

type CardProps = Pick<
  IProductPanelCardFields,
  'title' | 'price' | 'description'
> & {
  image: IImageWithFocalPoint | Asset
  link: string
  imageRatio?: number
  large?: boolean
  imageIsWide?: boolean
  onClick?: () => void
}

type ImageWrapperProps = {
  ratio?: number
}

type CardWrapperProps = {
  imageIsWide?: boolean
}

type ArrowProps = {
  boldText?: boolean
  large?: boolean
}

const ImageWrapper = styled.div<ImageWrapperProps>`
  position: relative;
  width: 100%;
  z-index: ${zIndex.above};
  overflow: hidden;
  padding-bottom: ${({ ratio }) => ratio * 100}%;
`

const Heading = styled(({ ...rest }) => <Type as="h2" {...rest} />)`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const CardWrapper = styled.div<CardWrapperProps>`
  position: relative;
  width: 100%;
  flex-basis: calc(50% - 12px);
  margin-bottom: 24px;

  ${({ imageIsWide }) =>
    imageIsWide &&
    css`
      flex: 1;
    `}

  ${media.md(css`
    flex-basis: calc(50% - 24px);
    margin-bottom: 48px;
  `)}

  &:nth-child(odd) {
    ${({ imageIsWide }) =>
      imageIsWide
        ? css`
            margin-right: ${({ theme }) => theme.spacing[2]};
          `
        : css`
            margin-right: 12px;
          `}

    ${media.md(css`
      margin-right: 24px;
      flex-basis: calc(50% - 24px);
    `)}
  }

  &:nth-child(even) {
    margin-left: 12px;

    ${media.md(css`
      margin-left: 24px;
      flex-basis: calc(50% - 24px);
    `)}
  }
`

const CardContainer = styled(props =>
  props.to ? <Link {...props} /> : <div {...props} />
)`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  overflow: hidden;
  z-index: ${zIndex.above};
  transition: transform ${durations.long}ms ease;

  ${({ to }) =>
    to &&
    css`
      &:hover,
      &:focus {
        transform: translate3d(0, -8px, 0);

        ${ImageWrapper}::before {
          transform: scale(1.1) translateZ(0);
        }
        ${Arrow} {
          transform: translate3d(-20%, 0, 0);
        }
      }
    `}
`

const AmountWrapper = styled(Type)`
  opacity: 0.75;
`

export const Arrow = styled.div<ArrowProps>`
  transition: transform ${durations.long}ms ease;
  margin-left: 5px;
  color: ${colors.deepBlue};

  ${({ boldText }) =>
    boldText
      ? css`
          padding-top: 0.35em;
        `
      : css`
          padding-top: 0.2em;
        `}

  ${media.mlg(css`
    padding-top: 0.45em;
  `)}

  .t-dark & {
    color: ${colors.blueOnDark};
  }
`

const CardContent = styled.div`
  flex: 1;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding-top: ${({ theme }) => theme.spacing[2]};
`

export const Card = ({
  title,
  price,
  description,
  link,
  image,
  imageRatio = 1.5,
  large,
  imageIsWide = false,
  onClick = undefined,
}: CardProps) => {
  const arrowSize = large
    ? { width: 14, height: 18 }
    : { width: 11, height: 14 }

  const isFocalImage = (
    image: IImageWithFocalPoint | Asset
  ): image is IImageWithFocalPoint => {
    return image && 'focalPoint' in image.fields
  }

  return (
    <CardWrapper imageIsWide={imageIsWide} onClick={onClick}>
      <CardContainer to={link}>
        {!!image && (
          <ImageWrapper ratio={imageRatio}>
            {isFocalImage(image) ? (
              <FocalImage
                image={image.fields.image}
                focalPoint={image.fields.focalPoint}
                sizes="(min-width: 75em) 50vw, (min-width: 48em) 25vw, 100vw"
              />
            ) : (
              <ContentfulImage
                image={image}
                sizes="(min-width: 75em) 50vw, (min-width: 48em) 25vw, 100vw"
              />
            )}
          </ImageWrapper>
        )}
        <CardContent>
          <Heading size={{ xs: 16, md: 30 }} weight="bold" bottom={{ xs: 0.5 }}>
            {title}
            {link && (
              <Arrow large={large}>
                <LinkArrow {...arrowSize} thick />
              </Arrow>
            )}
          </Heading>
          {price && (
            <AmountWrapper
              size={{ xs: 12, md: 16, mlg: 14 }}
              bottom={{ xs: 0.5 }}
            >
              <Amount
                value={price.fields}
                format={price.fields.priceFormat}
                fallback={description}
              />
            </AmountWrapper>
          )}
          {description && <Type size={{ xs: 14, md: 18 }}>{description}</Type>}
        </CardContent>
      </CardContainer>
    </CardWrapper>
  )
}
