import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { colors } from 'bl-common/src/constants/colors'
import { BackgroundImage } from 'bl-common/src/elements/BackgroundImage'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { between } from 'bl-common/src/utils/between'
import { media } from 'bl-common/src/utils/media'

type RowProps = {
  gradient?: any
  textColor?: string
}

const Row = styled.div<RowProps>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  ${({ gradient }) =>
    gradient
      ? gradient.fields &&
        css`
          background: linear-gradient(
            ${gradient.fields.degrees || 'to right'},
            ${gradient.fields.color1},
            ${gradient.fields.color2}
          );
          padding: ${({ theme }) => theme.spacing[1.5]}
            ${({ theme }) => theme.spacing[2]};
          padding-bottom: 0;
        `
      : css`
          margin: 0 ${({ theme }) => theme.spacing[-1]};
        `};

  color: ${props =>
    String(props.textColor).toLowerCase() === 'light'
      ? colors.white
      : 'inherit'};

  ${media.md(css`
    padding-bottom: ${({ theme }) => theme.spacing[2]};
  `)};
`

const RowItem = styled.div`
  flex: 0 0 100%;
  margin-bottom: ${({ theme }) => theme.spacing[2]};
  justify-content: space-between;

  ${media.sm(css`
    flex: 0 0 48%;
  `)};

  ${media.md(css`
    flex: 1 0 22%;
    padding: 0 ${({ theme }) => theme.spacing[1]};
  `)};
`

const Top = styled(({ image, gradient, ...rest }) =>
  image ? <BackgroundImage image={image} {...rest} /> : <div {...rest} />
)`
  height: ${between(150, 200)};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: ${({ theme }) => theme.spacing[1.5]};

  ${({ gradient }) =>
    gradient &&
    gradient.fields &&
    css`
      background-image: linear-gradient(
        ${gradient.fields.degrees || 'to right'},
        ${gradient.fields.color1},
        ${gradient.fields.color2}
      );
    `};
`

export const InfoColumnsRow = ({ items, gradient, textColor }) => {
  return (
    <Row gradient={gradient} textColor={textColor}>
      {items.slice(0, 4).map(item => (
        <RowItem key={item.fields.title}>
          {(item.fields.image || item.fields.gradient) && (
            <Top image={item.fields.image} gradient={item.fields.gradient} />
          )}

          {item.fields.title && (
            <Type
              preset="label"
              weight="bold"
              bottom={{ xs: 0.5, md: 0.5 }}
              case="uppercase"
            >
              {item.fields.title}
            </Type>
          )}
          <Type preset="label" weight="normal" multiline>
            {item.fields.text}
          </Type>
        </RowItem>
      ))}
    </Row>
  )
}

InfoColumnsRow.propTypes = {
  items: PropTypes.array.isRequired,
}
