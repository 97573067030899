import styled, { css } from 'styled-components'
import { Padding } from 'styled-components-spacing'

import { colors } from 'bl-common/src/constants/colors'
import { Accordion } from 'bl-common/src/elements/Accordion'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { Appear } from 'bl-common/src/units/Appear'
import { OffsetSection } from 'bl-common/src/units/Section/OffsetSection'
import { media } from 'bl-common/src/utils/media'

import { formatGradient } from '../../../utils/formatters'

type ContentProps = {
  desktopDirection?: string
}

type RightProps = {
  desktopDirection?: string
}

export const Content = styled.div<ContentProps>`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => `${theme.spacing[5.5]} ${theme.spacing[2.5]}`};

  ${media.md(css`
    flex-direction: ${({ desktopDirection }: ContentProps) => desktopDirection};
    justify-content: space-between;
    padding: ${({ theme }) => `${theme.spacing[5.5]} ${theme.spacing[4.5]}`};
  `)}
`

export const Left = styled.div`
  flex: 1 0 20%;
`

export const Right = styled.div<RightProps>`
  flex: 1 0 60%;

  ${media.md(css`
    padding-left: ${({ theme, desktopDirection }: any) =>
      desktopDirection === 'column' ? '0' : theme.spacing[10]};
  `)}
`

const FAQSection = ({
  section: {
    fields: {
      qaItems,
      heading,
      description,
      gradient,
      textColor,
      offsetDirection,
      desktopDirection = 'row',
      config,
    },
  },
}) => {
  const formattedGradient = formatGradient(gradient)
  const color = textColor === 'light' ? colors.light : colors.dark
  return (
    <Appear observer>
      <OffsetSection
        bottom={{ xs: 2, md: 6 }}
        top={{ xs: 2, md: 6 }}
        config={config}
        offsetDirection={offsetDirection}
        fullWidthMobile={true}
        background={formattedGradient}
      >
        <Content desktopDirection={desktopDirection}>
          <Left>
            <Appear>
              {heading && (
                <Type
                  as="h2"
                  size={{ xs: 30, md: 40 }}
                  weight="bold"
                  bottom={description ? 2 : 5}
                  maxWidth={300}
                  color={color}
                >
                  {heading}
                </Type>
              )}
              {description && (
                <Type
                  preset="subtitle"
                  bottom={{ xs: 2, md: 4 }}
                  multiline
                  maxWidth={500}
                  color={color}
                >
                  {description}
                </Type>
              )}
            </Appear>
          </Left>

          <Right desktopDirection={desktopDirection}>
            {qaItems.map(item => (
              <Appear delay={100} key={item.fields && item.fields.question}>
                <Padding bottom={2}>
                  <Accordion
                    label={item.fields && item.fields.question}
                    content={item.fields && item.fields.answer}
                    textColor={color}
                  />
                </Padding>
              </Appear>
            ))}
          </Right>
        </Content>
      </OffsetSection>
    </Appear>
  )
}

export default FAQSection
