import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'next/router'
import axios from 'axios'
import styled, { css } from 'styled-components'

import { Markdown } from 'bl-common/src/elements/Markdown/Markdown'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { IFormSection } from 'bl-common/src/generated/contentful'
import { Form } from 'bl-common/src/units/Form/Form'
import { Section } from 'bl-common/src/units/Section/Section'
import { media } from 'bl-common/src/utils/media'
import { triggerEvent } from 'bl-utils/src/analytics/events'

import { validate } from '../../../lib/utils/form'

const FormContainer = styled.div`
  display: flex;
`

const Left = styled.div<{ $width: string | number }>`
  width: 100%;

  ${media.md(css<{ $width: string | number }>`
    width: ${({ $width }) => `${$width}%`};
  `)}
`

const formatData = (values, form, locale) => {
  const data = new FormData()
  let hasFiles = false

  form.fields.forEach(field => {
    if (field.type !== 'file') {
      return
    }

    hasFiles = true
    const value = values[field.key]
    if (!value) {
      return
    }

    values[field.key] = value.map(file => {
      data.append(field.key, file)
      return file.name
    })
  })

  if (!hasFiles) {
    return values
  }

  data.append('__data', JSON.stringify(values))
  data.append('__recaptcha', values.__recaptcha)
  data.append('__locale', locale)
  return data
}

const FormSection = ({
  section: {
    sys: { id },
    fields: {
      title,
      description,
      form,
      buttonText,
      successMessages,
      formWidth = 100,
      formPosition = 'left',
      id: sectionId,
    },
  },
}: {
  section: IFormSection
}) => {
  const router = useRouter()
  const { query } = router
  const { i18n } = useTranslation()
  const scrollRef = useRef<HTMLDivElement>(null)
  const [isSuccessful, setIsSuccessful] = useState(false)

  const onSubmit = async values => {
    const data = formatData(values, form, i18n.language)

    try {
      await axios.post(`/api/form/${id}`, data)
      return true
    } catch (error) {
      if (error.response && error.response.status === 422) {
        return new Error({ ...error.response.data, name: 'Validation error' })
      }

      return false
    }
  }

  const onSuccess = () => {
    scrollRef.current.scrollIntoView()
    setIsSuccessful(true)

    const {
      fields: { title },
    } = form

    triggerEvent({
      event: 'Form Submit',
      eventAction: 'Submit',
      eventLabel: title,
    })
  }

  const positionMap = {
    left: 'flex-start',
    right: 'flex-end',
    center: 'center',
  }

  const initialValues = form.fields.reduce((init, field) => {
    // Formik will pass a array of strings as "value" to checkboxes they don't have boolean initialValues.
    if (field.type === 'checkbox') {
      return { ...init, [field.key]: false }
      // Check for query parameters and use them as initial values if 'useDefaultValue' is set.
    } else if (field.useDefaultValue && query[field.key]) {
      return { ...init, [field.key]: query[field.key] }
    }
    return init
  }, {})

  return (
    <Section top={5} bottom={5} ref={scrollRef} id={sectionId}>
      <FormContainer style={{ justifyContent: positionMap[formPosition] }}>
        <Left $width={formWidth}>
          {!isSuccessful && (
            <>
              {title && (
                <Type
                  as="h2"
                  preset="headlineLarge"
                  bottom={{ xs: description ? 1 : 3 }}
                >
                  {title}
                </Type>
              )}
              {description && (
                <Markdown bottom={{ xs: 3 }}>{description}</Markdown>
              )}
            </>
          )}
          <Form
            form={form}
            validate={values => validate(form, values, i18n.language)}
            recaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
            onSubmit={onSubmit}
            onSuccess={onSuccess}
            buttonText={buttonText}
            formSuccess={successMessages}
            initialValues={initialValues}
          />
        </Left>
      </FormContainer>
    </Section>
  )
}

export default FormSection
