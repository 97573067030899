import { BLOCKS } from '@contentful/rich-text-types'
import get from 'lodash/get'
import styled from 'styled-components'

import { breakpoints } from '../../constants/breakpoints'
import { colors } from '../../constants/colors'
import { durations } from '../../constants/durations'
import { gridOffsetFrom, gridOffsetTo } from '../../constants/sizes'
import { zIndex } from '../../constants/zIndex'
import { LinkArrow } from '../../elements/Arrow/Arrow'
import { ContentfulImage } from '../../elements/ContentfulImage'
import { Type } from '../../elements/Typography/Typography'
import { useBreakpoints } from '../../hooks/useBreakpoints'
import { RichTextRenderer } from '../../richText/RichTextRenderer'
import { between } from '../../utils/between'
import { mixins } from '../../utils/mixins'
import Steam from './Steam'

type FrameProps = {
  frameGradientColor1?: any
  frameGradientColor2?: any
  frameGradientDegrees?: any
  frameGradientOpacity?: any
}

const Frame = styled.div<FrameProps>(
  ({
    frameGradientColor1,
    frameGradientColor2,
    frameGradientDegrees,
    frameGradientOpacity,
  }) => ({
    position: 'relative',
    left: 75,

    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: -37.5,
      transform: 'translate3d(0, 70%, 0)',
      opacity: 0,
      zIndex: zIndex.behind,
      animation: 'fade-up-sm 2800ms 500ms ease forwards',
      background: `linear-gradient(${frameGradientDegrees}, ${frameGradientColor1}, ${frameGradientColor2})`,

      [`@media (min-width: ${breakpoints.md}px)`]: {
        animation: 'fade-up-md 2800ms 500ms ease forwards',
        left: mixins.span({ columns: 1, gutters: 1, noCss: true }) as string,
        transform: 'translate3d(0, -10px, 0)',
      },
    },

    [`@media (min-width: ${breakpoints.md}px)`]: {
      position: 'absolute',
      right: 0,
      left: between(gridOffsetFrom, gridOffsetTo),
      paddingLeft: mixins.span({
        columns: 3,
        gutters: 3,
        noCss: true,
      }) as string,
    },

    '@keyframes fade-up-sm': {
      '0%': {
        opacity: 0,
        transform: 'translate3d(0, 70%, 0)',
      },
      '100%': {
        opacity: frameGradientOpacity !== undefined ? frameGradientOpacity : 1,
        transform: 'translate3d(0, 50%, 0)',
      },
    },

    '@keyframes fade-up-md': {
      '0%': {
        opacity: 0,
        transform: 'translate3d(0, -10px, 0)',
      },
      '100%': {
        opacity: frameGradientOpacity != undefined ? frameGradientOpacity : 1,
        transform: 'translate3d(0, -80px, 0)',
      },
    },
  })
)

const ImageWrapper = styled.div({
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
  height: 456,

  [`@media (min-width: ${breakpoints.md}px)`]: {
    height: '95vh',
  },

  [`@media (min-width: ${breakpoints.mlg}px)`]: {
    height: '100vh',
  },
})

const ImageInnerWrapper = styled.div({
  width: 'calc(100% + 50px)',
  height: '100%',
  right: -50,
  bottom: -50,
  maxWidth: '100%',
  animationName: 'initial',
  transform: 'translate3d(-50px, 0, 0)',
  willChange: 'transform',

  opacity: 0.5,
  animation: 'fade 2000ms ease forwards, move 5000ms ease forwards',

  [`@media (min-width: ${breakpoints.md}px)`]: {
    right: -100,
    maxWidth: 'inherit',
    width: 'calc(100% + 100px)',
    transform: 'translate3d(-100px, 0, 0)',
    animation: 'fade 2000ms ease forwards, move-large 5000ms ease forwards',
  },

  '@keyframes fade': {
    '0%': {
      opacity: 0.5,
    },
    '100%': {
      opacity: 1,
    },
  },
  '@keyframes move': {
    '0%': {
      transform: 'translate3d(-50px, 0, 0)',
    },
    '100%': {
      transform: 'translate3d(0, -25px, 0)',
    },
  },
  '@keyframes move-large': {
    '0%': {
      transform: 'translate3d(-100px, 0, 0)',
    },
    '100%': {
      transform: 'translate3d(0, -25px, 0)',
    },
  },
})

const Container = styled.div({
  overflow: 'hidden',

  [`@media (min-width: ${breakpoints.md}px)`]: {
    position: 'relative',
    minHeight: '100vh',
  },
})

const Content = styled.div(({ theme }) => ({
  position: 'relative',
  top: -44,
  padding: `0 ${theme.spacing[2.5]}`,
  zIndex: zIndex.above,
  width: '90vw',
  opacity: 0,
  maxWidth: 400,
  transform: 'translateY(50px)',
  animation:
    'fade 1900ms 500ms ease forwards, move-sm 2950ms 550ms ease forwards',

  [`@media (min-width: ${breakpoints.md}px)`]: {
    animation:
      'fade 1900ms 1000ms ease forwards, move-md 2950ms 1100ms ease forwards',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-30%)',
    padding: 0,
    left: between(gridOffsetFrom, gridOffsetTo),
  },

  [`@media (min-width: ${breakpoints.mlg}px)`]: {
    maxWidth: 500,
  },

  [`@media (min-width: ${1600}px)`]: {
    left: 120,
  },

  [`@media (min-width: ${breakpoints.xl}px)`]: {
    maxWidth: between(400, 500),
  },

  [`@media (min-width: ${2000}px)`]: {
    left: 160,
  },

  '@keyframes fade': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },

  '@keyframes move-sm': {
    '0%': {
      transform: 'translateY(50px)',
    },
    '100%': {
      transform: 'translateY(0)',
    },
  },

  '@keyframes move-md': {
    '0%': {
      transform: 'translateY(-30%)',
    },
    '100%': {
      transform: 'translateY(-50%)',
    },
  },
}))

const Arrow = styled.div({
  color: colors.deepBlue,
  position: 'absolute',
  top: '100%',
  left: 0,
  opacity: 0,
  animation: `fade 1000ms 3500ms forwards, jump ${
    durations.long * 2
  }ms ease-in-out infinite`,
  animationPlayState: 'running',

  '@keyframes fade': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },

  '@keyframes jump': {
    '0%': {
      transform: ' translateY(0)',
    },
    '50%': {
      transform: 'translateY(50px)',
    },
    '100%': {
      transform: 'translateY(0)',
    },
  },

  '.t-dark &': {
    color: colors.blueOnDark,
  },
})

const Description = styled.div(({ theme }) => ({
  [`@media (min-width: ${breakpoints.md}px)`]: {
    marginBottom: theme.spacing[3],
  },
}))

const documentOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_, children) => (
      <Type weight="light" size={{ xs: 16, md: 32 }}>
        {children}
      </Type>
    ),
  },
}

export const HeroHeader = ({
  image,
  frameGradient,
  title,
  description,
  showArrow,
}) => {
  const { mediaMlg, mediaMd } = useBreakpoints()

  return (
    <Container>
      <Steam preStart small={!mediaMd} />

      <Frame
        frameGradientColor1={get(frameGradient, 'color1', 'transparent')}
        frameGradientColor2={get(frameGradient, 'color2', 'transparent')}
        frameGradientDegrees={get(frameGradient, 'fields.degrees', '90deg')}
        frameGradientOpacity={get(frameGradient, 'fields.opacity', '0.8')}
      >
        <ImageWrapper>
          <ImageInnerWrapper>
            <ContentfulImage
              image={image}
              style={{
                objectPosition: mediaMlg ? '50% 50%' : '30% 50%',
              }}
              priority
            />
          </ImageInnerWrapper>
        </ImageWrapper>
      </Frame>

      <Content>
        <Type as="h1" preset="headlineExtraLarge" bottom={{ xs: 1, mlg: 3 }}>
          {title}
        </Type>

        {!!description && (
          <Description>
            <RichTextRenderer
              document={description}
              customOptions={documentOptions}
            />
          </Description>
        )}

        {showArrow && mediaMd && (
          <Arrow>
            <LinkArrow rotate={90} height={32} width={32} />
          </Arrow>
        )}
      </Content>
    </Container>
  )
}
