import styled from 'styled-components'

import { zIndex } from '../constants/zIndex'
import { VisuallyHidden } from '../units/VisuallyHidden'
import { Link } from './Link'

export const BackgroundLink = styled(
  ({ linkComponent: LinkComponent, children, zIndex, to, ...rest }) => {
    if (LinkComponent) {
      // Use the provided link component
      return (
        <LinkComponent {...rest} href={to}>
          <VisuallyHidden>{children}</VisuallyHidden>
        </LinkComponent>
      )
    }

    // Use the default Link component
    return (
      <Link {...rest} to={to}>
        <VisuallyHidden>{children}</VisuallyHidden>
      </Link>
    )
  }
)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  outline: 0;
  cursor: pointer;
  z-index: ${zIndex.above};
`
