import get from 'lodash/get'

import { Section } from 'bl-common/src/units/Section/Section'

import { OfferPanel } from '../../OfferPanel'

const checkValidity = expiryDate => {
  if (!expiryDate) {
    return true
  }

  return new Date(expiryDate) > new Date()
}

export const OfferPanelSection = ({
  section: {
    fields: { offerPanel, config },
  },
}) => {
  const expiryDate = get(offerPanel, 'fields.expiryDate')
  return checkValidity(expiryDate) ? (
    <Section bottom={{ xs: 4, md: 6 }} top={{ xs: 2, md: 10 }} config={config}>
      <OfferPanel {...offerPanel} />
    </Section>
  ) : null
}

export default OfferPanelSection
