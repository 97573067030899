import get from 'lodash/get'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { colors } from '../../constants/colors'
import { gridOffsetFrom } from '../../constants/sizes'
import { zIndex } from '../../constants/zIndex'
import { BackgroundImage } from '../../elements/BackgroundImage'
import { ResponsiveParallax } from '../../units/ResponsiveParallax'
import { between } from '../../utils/between'
import { media, mediaMax } from '../../utils/media'

type ContainerProps = {
  degrees?: any
  color1?: any
  color2?: any
}

const ImageWrapper = styled(ResponsiveParallax)`
  position: absolute;
  width: 55%;
  will-change: transform;
  z-index: ${zIndex.above};

  ${media.md(css`
    width: calc(50% + 50px);
  `)};

  ${media.lg(css`
    width: calc(50% + 100px);
  `)};

  &:nth-child(1) {
    left: 0;
    top: ${between(-40, -140)};

    ${media.md(css`
      top: ${between(-10, -100)};
    `)}

    ${media.lg(css`
      top: -100px;
    `)};
  }

  &:nth-child(2) {
    right: 0;
    bottom: ${between(-40, -140)};

    ${media.md(css`
      bottom: ${between(-20, -100)};
    `)}

    ${media.lg(css`
      bottom: -100px;
    `)};
  }
`

const StyledImage = styled(BackgroundImage)`
  padding-bottom: 100%;
  background-position: center;
  background-size: cover;
  width: 100%;
  will-change: transform;

  ${media.md(css`
    padding-bottom: 66%;
  `)};

  ${media.lg(css`
    padding-bottom: 60%;
  `)};
`

const Container = styled.div<ContainerProps>`
  position: relative;
  margin: 0 auto;
  margin-top: ${between(20, 100)};
  margin-bottom: ${between(20, 100)};
  width: 100%;
  max-width: 1360px;
  height: ${between(245, 515)};
  max-height: 515px;

  ${media.md(css`
    height: ${between(124, 515)};
  `)}

  ${media.lg(css`
    margin-top: 70px;
    margin-bottom: 100px;
  `)};

  &::after {
    content: '';
    display: block;
    left: 0;
    margin: auto;
    height: 100%;
    position: absolute;
    right: 0;
    width: 65.56%;
    top: 0;

    ${props => css`
      background: linear-gradient(
        ${props.degrees || '320deg'},
        ${props.color1 || colors.white},
        ${props.color2 || colors.white}
      );
    `};
  }
`

const ImageGridWrapper = styled.div`
  ${mediaMax.md(css`
    margin: 0 -${gridOffsetFrom / 2}px;
  `)};
`

export const ImageGrid = ({ images = [], gradient }) => (
  <ImageGridWrapper>
    <Container
      color1={get(gradient, 'color1')}
      color2={get(gradient, 'color2')}
      degrees={get(gradient, 'degrees')}
    >
      {images.map((image, index) => (
        <ImageWrapper
          key={(image.sys && image.sys.id) || image}
          to={index === 0 ? 4 : -4}
        >
          <StyledImage image={image} />
        </ImageWrapper>
      ))}
    </Container>
  </ImageGridWrapper>
)

ImageGrid.propTypes = {
  images: PropTypes.array.isRequired,
  gradient: PropTypes.any.isRequired,
}
