import { observe, useObserver, viewportWidth } from 'react-ui-observer'
import styled, { css } from 'styled-components'

import { SmallGradientOffsetCard } from 'bl-common/src/cards/SmallGradientOffsetCard/SmallGradientOffsetCard'
import { breakpoints } from 'bl-common/src/constants/breakpoints'
import { Button } from 'bl-common/src/elements/Button/Button'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { Carousel } from 'bl-common/src/units/Carousel/Carousel'
import { media } from 'bl-common/src/utils/media'

export const Container = styled.div`
  ${media.md(css`
    display: flex;
  `)}
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: ${({ theme }) => theme.spacing[3]};

  ${media.md(css`
    padding-right: ${({ theme }) => theme.spacing[5]};
  `)}
`

export const CardContainer = styled.div`
  ${media.md(css`
    display: flex;
  `)}
`

export const Card = styled.div`
  flex: 0 1 100%;
  position: relative;
  width: 100%;

  ${media.mlg(css`
    &:not(:last-child) {
      margin-right: ${({ theme }) => theme.spacing[3]};
    }
  `)};

  ${media.md(css`
    padding-left: ${({ theme }) => theme.spacing[1.5]};
    padding-right: ${({ theme }) => theme.spacing[1.5]};
    max-width: calc(3 / 6 * 100%);
  `)}
`

const renderStories = stories =>
  stories.map(story => (
    <Card key={story.fields.title}>
      <SmallGradientOffsetCard
        layout="Landscape"
        title={story.fields.title}
        image={story.fields.heroImage.fields.image}
        gradient={story.fields.gradient}
        primaryCtaUrl={`/stories/${story.fields.slug}`}
        evenHeight
      />
    </Card>
  ))

const renderDesktopCards = stories => {
  if (!stories || !stories.length) {
    return null
  }

  return <CardContainer>{renderStories(stories)}</CardContainer>
}

const renderMobileCards = stories => {
  if (!stories || !stories.length) {
    return null
  }

  return (
    <Carousel
      minItemWidthFrom={200}
      minItemWidthTo={277}
      scrollKey="stories"
      pageMargin={2}
    >
      {renderStories(stories)}
    </Carousel>
  )
}

export const StoryBlock = ({ title, description, button, stories }) => {
  // TODO: Switch to useBreakpoints
  const isMobile = useObserver(
    observe(viewportWidth(), width => width < breakpoints.md)
  )

  return (
    <Container>
      <TextContainer>
        <Type as="h2" preset="headlineLarge" bottom={{ xs: 0.5, md: 1 }}>
          {title}
        </Type>
        {description && (
          <Type preset="subtitle" bottom={{ md: 5 }} maxWidth={650} multiline>
            {description}
          </Type>
        )}
        {!isMobile && button && (
          <Button
            paddingSize={button.paddingSize}
            preset={button.preset}
            to={button.to}
            emailTrigger={button.emailTrigger}
          >
            {button.text}
          </Button>
        )}
      </TextContainer>
      {isMobile ? renderMobileCards(stories) : renderDesktopCards(stories)}
    </Container>
  )
}
