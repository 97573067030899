/* eslint-disable @typescript-eslint/unbound-method */
import { Children, cloneElement, Component, Fragment } from 'react'
import { pure } from 'recompose'
import styled, { css } from 'styled-components'

import { nestedCalc } from 'bl-utils/src/nestedCalc'

import { colors } from '../constants/colors'
import { durations } from '../constants/durations'
import { modularScale } from '../constants/sizes'
import { zIndex } from '../constants/zIndex'
import { Amount } from '../elements/Amount'
import { Arrow } from '../elements/Arrow/Arrow'
import { Link } from '../elements/Link'
import { Type } from '../elements/Typography/Typography'
import { StyledImage } from '../units/SmartImage/StyledImage'
import { between } from '../utils/between'
import { media, mediaMax } from '../utils/media'
import { Carousel, CarouselContainer } from './Carousel/Carousel'
import { NextPrevious } from './Carousel/NextPrevious'
import { PageControl } from './Carousel/PageControl'
import { ScrollContainer } from './Carousel/ScrollContainer'
import { Shadow } from './Shadow'

type SlideItemProps = {
  title: string
  subtitle?: string
  description?: string
  mobileDescription?: string
  image: any
  gradent?: any
  gradient?: any
  isLeft?: boolean
  isRight?: boolean
  link?: string
  priceRef?: any
  priceFormat?: any
  isSuite?: boolean
  ctaLabel?: string
  tall?: boolean
}

type SlideItemState = {
  isHovered?: boolean
}

type GradientDecoratorProps = {
  degrees?: any
  color1?: any
  color2?: any
}

type ItemContentProps = {
  minHeight?: number
}

type ItemContainerProps = {
  isLeft?: boolean
  isRight?: boolean
}

const hoverTransitionTiming = css`
  transition: transform ${durations.short}ms ease;
`

const HoverText = styled(Type)`
  opacity: 0;
  transform: translateX(-1em);
  text-transform: uppercase;
  transition:
    transform ${durations.short}ms ease,
    opacity ${durations.micro}ms ease;
`

const HoverTextContainer = styled.div`
  display: none;

  ${media.md(css`
    display: flex;
  `)};
`

const ItemImage = styled(StyledImage)`
  ${hoverTransitionTiming};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: ${zIndex.above};
  width: 100%;

  &::before {
    content: '';
    display: block;
    padding-bottom: 75.15%;
  }

  ${media.md(css`
    left: 0;
    position: absolute;
    top: 0;
    height: ${props => nestedCalc(`100% - ${props.theme.spacing['3.5']}`)};
    width: 73.73%;
    &::before {
      content: initial;
    }
  `)};
`

const Headline = styled(Type)`
  text-transform: uppercase;
  ${media.md(css`
    text-transform: initial;
  `)};
`

const ItemContentWrapper = styled.div`
  color: ${colors.dark};
  height: ${props => nestedCalc(`100% - ${props.theme.spacing['3.5']}`)};
  width: 100%;

  ${media.md(css`
    position: absolute;
    top: 0;
    padding-top: ${({ theme }) => theme.spacing['3.5']};
  `)};
`

const Description = styled(({ shouldDisappear, ...rest }) => (
  <Type {...rest} />
))`
  ${({ shouldDisappear }) =>
    shouldDisappear &&
    css`
      ${mediaMax.md(css`
        display: none;
      `)};
    `};
`

const MobileDescription = styled(Type)`
  display: none;
  ${mediaMax.md(css`
    display: block;
  `)};
`

const GradientDecorator = styled.div<GradientDecoratorProps>`
  ${hoverTransitionTiming};
  padding-top: 50%;
  ${props => css`
    background: linear-gradient(
      ${props.degrees || 'to right'},
      ${props.color1 || colors.white},
      ${props.color2 || colors.white}
    );
  `};

  ${media.md(css`
    position: absolute;
    height: 100%;
    width: 64.5%;
    padding-top: 0;
    left: 35.5%;
  `)};

  ${media.lg(css`
    width: 67.5%;
  `)};
`

const ItemContent = styled.div<ItemContentProps>`
  bottom: 24px;
  left: 24px;
  right: 24px;
  top: auto;
  position: absolute;
  padding: 40px 32px;
  transition: transform ${durations.medium}ms 100ms ease-out;
  z-index: ${zIndex.above2};
  text-align: center;

  ${({ minHeight }) =>
    minHeight &&
    css`
      min-height: ${between(minHeight / modularScale, minHeight)};
    `};

  &:hover {
    ${HoverText} {
      opacity: 1;
      transform: translateX(2em);
    }
  }

  &::before {
    ${hoverTransitionTiming};
    background: ${colors.white};
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: ${zIndex.behind};
  }

  ${media.md(css`
    padding: ${({ theme }) => theme.spacing[4]};
    bottom: 0;
    right: initial;
    top: initial;
    left: ${props => nestedCalc(`35.5% + ${props.theme.spacing['3.5']}`)};
    width: ${between(491 / modularScale, 491)};
    text-align: left;

    &:hover::before {
      transform: scale(1.035);
    }
  `)};

  ${media.xl(css`
    width: ${between(600 / modularScale, 600)};
  `)};

  /* Prevent react hover event from triggering on tap */
  ${mediaMax.md(css`
    pointer-events: none;
  `)};
`

const ItemContainer = styled(({ isLeft, isRight, isHovered, ...rest }) => (
  <Link {...rest} />
))<ItemContainerProps>`
  display: block;
  flex: none;
  position: relative;
  width: 100%;
  ${media.md(css`
    &::before {
      content: '';
      display: block;
      padding-top: 56.78%;
    }
    ${(props: ItemContainerProps) =>
      props.isLeft &&
      css`
        ${ItemContent} {
          transform: translateX(-6rem);
          transition: transform ${durations.long}ms;
        }
        ${ItemImage} {
          transform: translateX(-7rem);
          transition: transform ${durations.long}ms;
        }
      `};

    ${(props: ItemContainerProps) =>
      props.isRight &&
      css`
        ${ItemContent} {
          transform: translateX(6rem);
          transition: transform ${durations.long}ms;
        }
      `};
  `)};
  ${media.lg(css`
    &::before {
      padding-top: 54.78%;
    }
  `)};
  ${media.xl(css`
    &::before {
      padding-top: 52.78%;
    }
  `)};

  ${props =>
    props.tall &&
    mediaMax.md(css`
      ${ItemImage} {
        &::before {
          padding-bottom: 121%;
        }
      }
      ${GradientDecorator} {
        padding-top: 6rem;
      }
      ${ItemContent} {
        padding: 32px;
      }
    `)};
`

class SlideItem extends Component<SlideItemProps, SlideItemState> {
  constructor(props) {
    super(props)
    this.state = {
      isHovered: false,
    }
    this.handleMouseOver = this.handleMouseOver.bind(this)
    this.handleMouseOut = this.handleMouseOut.bind(this)
  }

  handleMouseOver() {
    this.setState(() => ({
      isHovered: true,
    }))
  }

  handleMouseOut() {
    this.setState(() => ({
      isHovered: false,
    }))
  }

  render() {
    const {
      title,
      subtitle,
      description,
      mobileDescription,
      image,
      gradient,
      isLeft,
      isRight,
      link = '/',
      priceRef,
      priceFormat,
      isSuite,
      ctaLabel,
    } = this.props

    const { isHovered } = this.state

    return (
      <ItemContainer
        isLeft={isLeft}
        isRight={isRight}
        to={link}
        isHovered={isHovered}
        tall={isSuite}
        data-cy="slider-item"
      >
        <ItemContentWrapper>
          <ItemImage
            image={image}
            style={
              isHovered
                ? {
                    transform: 'translate(-10px, -10px)',
                    willChange: 'transform',
                  }
                : {}
            }
          />
          <ItemContent
            onMouseOver={this.handleMouseOver}
            onMouseOut={this.handleMouseOut}
          >
            <Headline
              as="h3"
              size={{ xs: 16, md: 30 }}
              weight="bold"
              bottom={{ xs: 0.5, md: 1 }}
            >
              {title}
            </Headline>
            {priceRef && (
              <Type
                preset={isSuite ? 'labelSmall' : 'textLarge'}
                bottom={{ xs: isSuite ? 0 : 1, md: 2 }}
              >
                <Amount
                  value={priceRef}
                  format={priceFormat}
                  fallback={subtitle}
                />
              </Type>
            )}
            {description && (
              <Description
                shouldDisappear={!!mobileDescription}
                preset="textLarge"
                bottom={{ md: 2 }}
              >
                {description}
              </Description>
            )}
            {mobileDescription && (
              <MobileDescription preset="text">
                {mobileDescription}
              </MobileDescription>
            )}
            {ctaLabel && (
              <HoverTextContainer>
                <Arrow />
                <HoverText preset="labelSmall">{ctaLabel}</HoverText>
              </HoverTextContainer>
            )}
            <Shadow zIndex={-2} />
          </ItemContent>
          <GradientDecorator
            color1={gradient && gradient.fields && gradient.fields.color1}
            color2={gradient && gradient.fields && gradient.fields.color2}
            degrees={gradient && gradient.fields && gradient.fields.degrees}
            style={
              isHovered
                ? {
                    transform: 'translate(10px, 10px)',
                    willChange: 'transform',
                  }
                : {}
            }
          />
        </ItemContentWrapper>
      </ItemContainer>
    )
  }
}

export const Slider = pure(({ items = [], pageMargin, scrollKey, isSuite }) => (
  <Carousel
    scrollKey={scrollKey}
    pageMargin={pageMargin}
    render={renderProps => (
      <Fragment>
        <CarouselContainer>
          <ScrollContainer {...renderProps}>
            {Children.map(renderProps.children, (child, index) =>
              cloneElement(child, {
                isSuite,
                isLeft:
                  !renderProps.touchScrolling &&
                  renderProps.currentPage > index,
                isRight:
                  !renderProps.touchScrolling &&
                  renderProps.currentPage < index,
              })
            )}
          </ScrollContainer>
          <NextPrevious {...renderProps} />
        </CarouselContainer>
        <PageControl {...renderProps} />
      </Fragment>
    )}
  >
    {items.map(item => (
      <SlideItem
        tall={item.isSuite}
        key={item.sys.id}
        title={item.fields.title}
        subtitle={item.fields.subtitle}
        description={item.fields.description}
        mobileDescription={item.fields.mobileDescription}
        link={item.fields.link}
        image={item.fields.image}
        gradient={item.fields.gradient}
        priceRef={item.fields.priceRef}
        priceFormat={item.fields.priceFormat}
        ctaLabel={item.fields.ctaLabel}
      />
    ))}
  </Carousel>
))
