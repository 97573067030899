import get from 'lodash/get'
import styled, { css } from 'styled-components'

import { PackageCard } from 'bl-common/src/cards/PackageCard/PackageCard'
import { modularScale } from 'bl-common/src/constants/sizes'
import { zIndex } from 'bl-common/src/constants/zIndex'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { IGradient } from 'bl-common/src/generated/contentful'
import { Appear } from 'bl-common/src/units/Appear'
import { CardCarousel } from 'bl-common/src/units/CardCarousel/CardCarousel'
import { Section } from 'bl-common/src/units/Section/Section'
import { between } from 'bl-common/src/utils/between'
import { media } from 'bl-common/src/utils/media'

import { formatPackageCard } from '../../../utils/formatters'
import { getSectionProps } from '../../../utils/getSectionProps'

type PackagePairProps = {
  bottom?: string
  gradient?: IGradient
}

type PackageListProps = {
  gradient?: IGradient
}

export const PackagePair = styled.div<PackagePairProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  margin-bottom: ${({ bottom }) =>
    bottom === 'large'
      ? between(120 / modularScale, 120)
      : between(80 / modularScale, 80)}};

  [data-packagecard='true'] {
    margin-bottom: ${between(40 / modularScale, 40)};
  }

  ${media.md(css`
    flex-direction: row;
    [data-packagecard='true'] {
      z-index: ${zIndex.above};
      margin-bottom: 0;

      &:first-child {
        flex: 1 0 60%;
        margin-right: ${between(80 / modularScale, 80)};
      }

      &:last-child {
        flex: 1 0 30%;
        transform: translateY(${between(80 / modularScale, 80)});
      }
    }
  `)}

  &::after {
    content: '';
    height: 100%;
    left: ${between(40 / modularScale, 40)};
    right: ${between(40 / modularScale, 40)};
    position: absolute;
    transform: translateY(${between(40 / modularScale, 40)});
  }

  ${({ gradient }) =>
    gradient &&
    media.md(css`
      &::after {
        background: linear-gradient(
          ${get(gradient, 'fields.degrees', '90deg')},
          ${get(gradient, 'fields.color1')},
          ${get(gradient, 'fields.color2')}
        );
      }
    `)};
`

export const PackageList = styled.div<PackageListProps>`
  position: relative;
  z-index: ${zIndex.above};

  [data-carouselitemwrap='true'] {
    margin-top: 80px;
    z-index: ${zIndex.above};

    &:first-child {
      margin-top: 0;
    }
  }

  &::after {
    content: '';
    top: ${between(70 / modularScale, 70)};
    left: ${between(40 / modularScale, 40)};
    right: ${between(40 / modularScale, 40)};
    bottom: ${between(120 / modularScale, 120)};
    position: absolute;
  }

  ${({ gradient }) =>
    gradient &&
    media.lg(css`
      &::after {
        background: linear-gradient(
          ${get(gradient, 'fields.degrees', '90deg')},
          ${get(gradient, 'fields.color1')},
          ${get(gradient, 'fields.color2')}
        );
      }
    `)};
`

const checkValidity = expiryDate => {
  if (!expiryDate) {
    return true
  }

  return new Date(expiryDate) > new Date()
}

const PackageCardsSection = props => {
  const { heading, multilineSubheading, cards, backgroundGradient, config } =
    getSectionProps(props)

  const validCards = cards.filter(card =>
    checkValidity(get(card, 'fields.expiryDate'))
  )
  const formattedCards = validCards.map(card => formatPackageCard(card))

  const cardCount = formattedCards.length
  return (
    <Section top={{ xs: 4, md: 6 }} bottom={{ xs: 4, md: 6 }} config={config}>
      <Appear observer>
        <Appear>
          <Type as="h2" preset="headlineLarge" bottom={{ xs: 0.5, md: 1 }}>
            {heading}
          </Type>
        </Appear>
        {multilineSubheading && (
          <Appear>
            <Type
              preset="subtitle"
              multiline
              maxWidth={780}
              bottom={{ xs: 2, md: 5 }}
            >
              {multilineSubheading}
            </Type>
          </Appear>
        )}
      </Appear>
      {cardCount > 2 ? (
        <PackageList gradient={backgroundGradient}>
          <CardCarousel
            cards={formattedCards}
            minItemWidthFrom={250}
            minItemWidthTo={400}
            id={'testCarousle'}
          />
        </PackageList>
      ) : (
        <PackagePair
          gradient={backgroundGradient}
          bottom={cardCount === 1 && 'large'}
        >
          {formattedCards.map((card, index) => (
            <PackageCard {...card} key={index} />
          ))}
        </PackagePair>
      )}
    </Section>
  )
}

export default PackageCardsSection
