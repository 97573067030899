import styled, { css } from 'styled-components'

import { durations } from 'bl-common/src/constants/durations'
import { zIndex } from 'bl-common/src/constants/zIndex'
import { media, mediaMax } from 'bl-common/src/utils/media'

const ThumbnailWrap = styled.div`
  display: flex;
  flex-direction: column;

  ${media.md(css`
    & + & {
      margin-left: 40px;
    }
  `)}
`

const ThumbnailClickable = styled.button`
  text-align: left;
  cursor: pointer;
`

const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.1);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0;
  z-index: ${zIndex.above};
  animation: higlight-overlay-fade ${durations.short}ms ease forwards;

  ${media.md(css`
    background: rgba(0, 0, 0, 0.5);
  `)}

  @keyframes higlight-overlay-fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

const OverlayIcon = styled.div`
  background: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  bottom: 0;
  height: 40px;
  left: 0;
  margin: auto;
  right: 0;
  position: absolute;
  top: 0;
  width: 40px;
  z-index: ${zIndex.above};

  ${media.md(css`
    width: 64px;
    height: 64px;
  `)}

  > svg {
    bottom: 0;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;

    ${mediaMax.md(css`
      width 12px;
    `)}
  }
`

const PlayIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 17 24"
    width="16"
    transform="translate(2,0)"
  >
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M4.26119 22.3503l10.82841-8.7981c.9851-.8004.9851-2.304 0-3.1044L4.26119 1.64971C2.95416.58775 1 1.51787 1 3.20194V20.7981c0 1.684 1.95416 2.6142 3.26119 1.5522z"
    />
  </svg>
)

export const HighlightsThumbnail = ({ onClick }) => (
  <ThumbnailWrap>
    <ThumbnailClickable onClick={onClick}>
      <Overlay>
        <OverlayIcon>
          <PlayIcon />
        </OverlayIcon>
      </Overlay>
    </ThumbnailClickable>
  </ThumbnailWrap>
)
